import { useCallback, useState } from 'react'
import PersonalizePage from './Personalize'
import useCrop from './hooks/useCrop'
import { Variant, PhotoProduct } from '../../clients/fagl-server/types/photoProducts'
import { DesignTemplate, PhotoProductEditCompleteCallback } from '../../clients/fagl-server/types'
import PhotoSelector from './components/PhotoSelector'
import Button from '../../shared/buttons/Button'
import UserIcon from './user-icon.svg?react'
import FaceIcon from './face-icon.svg?react'
import LightingIcon from './lighting-icon.svg?react'
import LargeImageIcon from './large-image-icon.svg?react'
import useHeadSegmentation from './hooks/useHeadSegmentation'
import Template from './select-template/Template'
import html2canvas from 'html2canvas-pro'
import { BaseTransition } from '../../shared/BaseTransition'
import { useApi } from '../../hooks/useApi'
import { PhotoSource } from '../../hooks/useRecordUserAction'

function getTemplatePreviewUrl(template: DesignTemplate) {
  return `/photo-products/templates/previews/${template.id}.png`
}

function TipsToChoosePhoto() {
  return (
    <div className="flex flex-col space-y-4 items-center">
      <h3 className="font-bold text-xl text-center">Tips to choose the right photo</h3>
      <ul className="grid grid-cols-2 gap-y-2 gap-x-8 text-sm pl-4">
        <li className="flex items-center gap-2">
          <UserIcon className="w-4" /> Only one person
        </li>
        <li className="flex items-center gap-2">
          <LightingIcon className="w-4" /> Good lighting
        </li>
        <li className="flex items-center gap-2">
          <FaceIcon className="w-4" /> Whole face visible
        </li>
        <li className="flex items-center gap-2">
          <LargeImageIcon className="w-4" /> Large image
        </li>
      </ul>
    </div>
  )
}

export default function HeadSegmentationEdit({
  cancelCartItemEdit,
  product,
  variants,
  next,
  previous,
  variant,
  sourceImages,
  isPreparingEdit,
  template,
  templateWidth,
  templateHeight,
  isEditMode,
}: {
  cancelCartItemEdit: () => void
  product: PhotoProduct
  variants: Variant[]
  currency: string
  next: PhotoProductEditCompleteCallback
  previous: () => void
  isPreparingEdit: boolean
  variant: Variant
  sourceImages: {
    original: string
    cropped: string
  }[]
  template: DesignTemplate
  templateWidth: number
  templateHeight: number
  isEditMode: boolean
}) {
  if (!template) {
    throw new Error('ERROR_CAUSED_BY_REFRESH')
  }

  const hsClient = useHeadSegmentation()
  const { recordDidSelectPhotos } = useApi().recordUserAction.photoProducts
  const [isConfirmingCrop, setIsConfirmingCrop] = useState(false)
  const [isNoFaceFound, setIsNoFaceFound] = useState(false)
  const [isPristine, setIsPristine] = useState(true)
  const { isCropping, initCrop, resetCrop, confirmCrop, closeCropDialog, urls } = useCrop(
    sourceImages[0]
      ? {
          original: sourceImages[0]?.original ?? null,
          cropped: sourceImages[0]?.cropped ?? null,
        }
      : null
  )

  const onPhotoSelected = useCallback((base64: string[], source: PhotoSource) => {
    setIsNoFaceFound(false)
    initCrop(base64[0])
    recordDidSelectPhotos({
      photosSelected: 1,
      photosRequired: 1,
      photoSource: source,
    })
    setIsPristine(false)
  }, [])

  const onCropConfirmed = useCallback(
    async (base64: string) => {
      const cell = template.cells[0]
      closeCropDialog()
      try {
        setIsConfirmingCrop(true)
        const head = await hsClient?.segmentHead(base64, cell.width, cell.height)
        confirmCrop(head ?? '')
        setIsPristine(false)
      } catch (error) {
        console.error(error)
        setIsNoFaceFound(true)
      } finally {
        setIsConfirmingCrop(false)
      }
    },
    [confirmCrop, hsClient, template, setIsPristine]
  )

  const onNext = useCallback(async () => {
    if (!urls.cropped) {
      return
    }

    // hide the overlay so it is not captured in the screenshot
    document.querySelectorAll('.template-cell-overlay').forEach((el) => {
      el.classList.add('hidden')
    })

    const canvas = await html2canvas(document.querySelector('#template')!, {
      allowTaint: true,
    })

    const productImageDataUrl = canvas.toDataURL()

    const payload = {
      productImageDataUrl,
      sourceImageDataUrls: [
        {
          original: urls.original ?? '',
          cropped: urls.cropped ?? '',
        },
      ],
    }

    return next({
      photoUrl: productImageDataUrl,
      sourceImageDataUrls: payload.sourceImageDataUrls,
      isPristine,
    })
  }, [product.providerProductId, urls.cropped, isEditMode, isPristine])

  const onNewPhotoSelected = useCallback(
    (dataUrls: string[]) => {
      resetCrop(dataUrls[0])
    },
    [resetCrop]
  )

  const openCropDialog = useCallback(() => {
    if (urls.original) {
      initCrop(urls.original)
    }
  }, [initCrop, urls.original])

  const templatePreviewUrl = getTemplatePreviewUrl(template)

  const showFirstScreen = !urls.original || (!isCropping && !urls.cropped)
  return (
    <>
      <BaseTransition show={showFirstScreen}>
        <div className="relative">
          <div className="h-screen bg-gray-2 relative flex flex-col pt-8 ">
            <header className="flex-0 px-4 space-y-2 mb-4 text-center">
              <h1 className="font-bold text-2xl">Select Photo</h1>
            </header>
            <main className="flex-1 px-4 space-y-4">
              <PhotoSelector
                numberOfPhotos={1}
                onPhotoSelected={onPhotoSelected}
                hideDevicePhotosUploadOption
              />
              <div className="w-full rounded-lg overflow-hidden bg-white">
                <img src={templatePreviewUrl} className="w-full" alt="template preview" />
              </div>
              {isNoFaceFound && (
                <div className="text-tomato-9 border bg-tomato-9/10 font-semibold border-tomato-9 rounded-lg p-2 text-center">
                  No face found. Please try again with a different photo.
                </div>
              )}
              <TipsToChoosePhoto />
            </main>
            <footer className="text-center space-y-4 p-2 bg-white sticky bottom-0">
              <div className="grid grid-cols-2 gap-2">
                <Button onClick={previous} colorVariant="primary:invert">
                  Back
                </Button>
                <Button
                  disabled={isPreparingEdit || !urls.cropped}
                  onClick={() => {}}
                  colorVariant="primary"
                >
                  Preview
                </Button>
              </div>
            </footer>
          </div>
        </div>
      </BaseTransition>
      <BaseTransition show={!showFirstScreen}>
        <PersonalizePage
          hideDevicePhotosUploadOption
          previewElement={
            <div className="max-w-full overflow-hidden max-h-[290px] mx-auto">
              <Template
                designTemplate={template}
                photos={[urls.cropped ?? '']}
                templateWidth={templateWidth}
                templateHeight={templateHeight}
              />
            </div>
          }
          key={variant.variantId}
          isConfirmingCrop={isConfirmingCrop}
          isPreparingEdit={isPreparingEdit}
          isEditMode={isEditMode}
          cancelCartItemEdit={cancelCartItemEdit}
          onNewPhotoSelected={onNewPhotoSelected}
          urls={urls}
          isCropping={isCropping}
          aspectRatio={variant.aspectRatio}
          confirmCrop={onCropConfirmed}
          openCropDialog={openCropDialog}
          closeCropDialog={closeCropDialog}
          next={onNext}
          initialVariantId={variant.variantId}
          variants={variants}
          previous={previous}
        />
      </BaseTransition>
      {isConfirmingCrop && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[9999]">
          <div className="w-8 h-8 border-4 border-white/20 border-t-white rounded-full animate-spin" />
        </div>
      )}
    </>
  )
}
