import React, { useEffect } from 'react'
import { PhotoProduct, Variant } from '../../../clients/fagl-server/types/photoProducts'
import { usePhotoProduct } from '../hooks/usePhotoProduct'
import LoadingOverlay from './LoadingOverlay'
import { useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'

export const usePhotoProductId = () => {
  const { photoProductId } = useParams<{ photoProductId: string }>()
  const variantId = useSearchParam('variantId')

  if (!photoProductId) {
    throw new Error('No product id provided')
  }

  return {
    photoProductId: parseInt(photoProductId, 10),
    variantId: variantId ? parseInt(variantId, 10) : undefined,
  }
}

export default function ProductWrapper({
  children,
}: {
  children: (props: {
    photoProduct: PhotoProduct
    variants: Variant[]
    productImages: string[]
    carouselImages: string[]
    initialVariantId?: number
  }) => React.ReactElement
}) {
  const { photoProductId, variantId } = usePhotoProductId()

  const {
    photoProduct,
    isLoading: isProductLoading,
    loadError: productLoadError,
    variants,
    load,
    productImages,
    carouselImages,
  } = usePhotoProduct()

  useEffect(() => {
    load(photoProductId)
  }, [photoProductId])

  if (isProductLoading) {
    return <LoadingOverlay>Loading product information</LoadingOverlay>
  }

  if (!photoProduct) {
    if (productLoadError === 'NOT_FOUND') {
      throw new Error('Product not found')
    }

    throw new Error('Something went wrong')
  }

  return children({
    photoProduct,
    variants,
    productImages,
    carouselImages,
    initialVariantId: variantId,
  })
}
