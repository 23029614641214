import { useCallback, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import ProductPage from './product-page/ProductPage'
import PreviewPage from './Preview'
import { create2DecimalsCurrencyFormatter } from '../../utils'
import usePrintify from './hooks/usePrintify'
import LogsPanel from './LogsPanel'
import {
  AddItemToCartPayload,
  addItemToCartPayloadSchema,
  CartItem,
} from '../../clients/fagl-server/types/photoProductsCart'
import { Variant, PhotoProduct } from '../../clients/fagl-server/types/photoProducts'
// import useCuratedPhotos from './hooks/useCuratedPhotos'
import {
  CellType,
  CuratedPhoto,
  DesignTemplate,
  PhotoProductEditCompleteCallback,
} from '../../clients/fagl-server/types'
import SelectTemplatePage from './select-template'
import EditTemplatePage from './edit-template'
import SinglePhotoEdit from './SinglePhotoEdit'
import LoadingOverlay from './components/LoadingOverlay'
import { useApi } from '../../hooks/useApi'
import HeadSegmentationEdit from './HeadSegmentationEdit'
import { PseudoTemplate } from '../../clients/pseudoTemplates'
import SelectPseudoTemplatePage from './select-template/SelectPseudoTemplate'
import BackFrontPseudoTemplateEdit from './BackFrontPseudoTemplateEdit'
import { ORDERED_PSEUDO_TEMPLATE_IDS, ORDERED_TEMPLATE_IDS } from './templateOrdering'

enum EditScreen {
  HEAD_SEGMENTATION = 'HEAD_SEGMENTATION',
  TEMPLATE = 'TEMPLATE',
  SINGLE_PHOTO = 'SINGLE_PHOTO',
  BACK_FRONT_PSEUDO_TEMPLATE = 'BACK_FRONT_PSEUDO_TEMPLATE',
}

function determineEditScreen(product: PhotoProduct) {
  if (product.category === 'SOCKS' || product.category === 'WRAPPING_PAPER') {
    return EditScreen.HEAD_SEGMENTATION
  }

  if (product.category === 'ORNAMENTS') {
    return EditScreen.BACK_FRONT_PSEUDO_TEMPLATE
  }

  if (product.category === 'MUGS' || product.category === 'PUZZLES') {
    return EditScreen.TEMPLATE
  }

  return EditScreen.SINGLE_PHOTO
}

export default function CreateFlow({
  editedCartItem,
  openTermsOfUsePage,
  isPrintifyPreviewLogsActive,
  addItemToCart,
  cancelCartItemEdit,
  photoProduct,
  variants,
  currency = 'USD',
  productImages,
  carouselImages,
  initialVariantId,
  cartHeader,
}: {
  editedCartItem: CartItem | null
  openTermsOfUsePage: () => void
  isPrintifyPreviewLogsActive: boolean
  addItemToCart: (payload: AddItemToCartPayload) => void
  cancelCartItemEdit: () => void
  photoProduct: PhotoProduct
  variants: Variant[]
  currency: string
  productImages: string[]
  carouselImages: string[]
  initialVariantId?: number
  cartHeader: (title: string) => React.ReactNode
}) {
  const {
    recordUserAction: {
      photoProducts: {
        recordDidTapProductPageC2A,
        recordDidTapPreview,
        recordDidSelectPodTemplate,
        recordDidSkipPreview,
      },
    },
    getPseudoTemplatesForVariant,
  } = useApi()

  const navigate = useNavigate()
  const { state: navigationState } = useLocation()
  const [creationFlowSource] = useState<string | null>(navigationState?.source)
  const defaultVariantId = initialVariantId ?? variants[0].variantId
  const {
    generatePreview,
    updateVariantId,
    previewPhotosList,
    isGettingPreview,
    showNotAllLoadedError,
    regeneratePreview,
    fetchLogs,
    sourceImagesDataUrls,
    createPayloadWithVariant,
    resetState,
    variantId,
    canSkipPreview,
    isPreparingEdit,
    resetSourcePhotos,
  } = usePrintify({
    providerProductId: photoProduct.providerProductId,
    defaultVariantId,
    editedCartItem,
  })

  const { providerProductId, hasDesignTemplates } = photoProduct

  const variantsById: Record<number, Variant> = useMemo(
    () => Object.fromEntries(variants.map((v) => [v.variantId, v])),
    [variants]
  )

  const variant = variantsById[variantId]
  const { price, salePrice, width: variantWidth, height: variantHeight, title } = variant
  const printAreaPositions = variant.printAreaPositions

  // const { curatedPhotos, arePhotosLoaded, getCuratedPhotos, isUsingFallbackForPhotoProducts } = useCuratedPhotos({
  //   forceFallback: true,
  // })

  const curatedPhotos: CuratedPhoto[] = []
  const arePhotosLoaded = true
  const isUsingFallback = true
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    editedCartItem?.metadata.designTemplateId ?? null
  )

  const pseudotemplatesForVariant = useMemo(() => {
    return getPseudoTemplatesForVariant(variantId)
  }, [getPseudoTemplatesForVariant, variantId])

  const [selectedPseudoTemplateId, setSelectedPseudoTemplateId] = useState<string | null>(
    editedCartItem?.metadata.pseudoTemplateId ?? null
  )

  const selectedPseudoTemplate = useMemo(() => {
    if (!selectedPseudoTemplateId) {
      return null
    }

    return pseudotemplatesForVariant.find((pt) => pt.id === selectedPseudoTemplateId) ?? null
  }, [pseudotemplatesForVariant, selectedPseudoTemplateId])

  const photoUrls = useMemo(() => {
    if (sourceImagesDataUrls.length > 0) {
      return sourceImagesDataUrls
    }

    return [] // curatedPhotos.map((photo) => ({ original: photo.url, cropped: photo.url }))
  }, [curatedPhotos, sourceImagesDataUrls])

  const selectedTemplate = useMemo(() => {
    return photoProduct.designTemplates.find((dt) => dt.id === selectedTemplateId) ?? null
  }, [photoProduct.designTemplates, selectedTemplateId])

  const navigateToSource = useCallback(() => {
    if (creationFlowSource) {
      navigate(`/photo-products/${creationFlowSource}`)
    } else {
      navigate('/photo-products')
    }
  }, [navigate, creationFlowSource])

  const priceFormatter = useMemo(() => create2DecimalsCurrencyFormatter(currency), [currency])

  const formattedPrices = useMemo(() => {
    const zeroPrice = `${priceFormatter(0)} ${currency}`
    return {
      price: price ? `${priceFormatter(price / 100)} ${currency}` : zeroPrice,
      salePrice: salePrice ? `${priceFormatter(salePrice / 100)} ${currency}` : zeroPrice,
    }
  }, [priceFormatter, currency, price, salePrice])

  const [isAddingItemToCart, setIsAddingItemToCart] = useState(false)
  const [isLogsPageVisible, setIsLogsPageVisible] = useState(false)

  const onTemplateSelected = useCallback(
    (template: DesignTemplate, position: number) => {
      const numberOfPhotoCells = template.cells.filter(
        (cell) => cell.type === CellType.Image
      ).length

      const isNewTemplateRequireMorePhotosThanWeHave = numberOfPhotoCells > photoUrls.length

      if (isNewTemplateRequireMorePhotosThanWeHave) {
        resetSourcePhotos()
      }

      setSelectedTemplateId(template.id)
      recordDidSelectPodTemplate({
        podTemplateId: template.id,
        podTemplatePosition: position,
      })
      navigate('edit')
    },
    [navigate, recordDidSelectPodTemplate, photoUrls, resetSourcePhotos]
  )

  const onPseudoTemplateSelected = useCallback(
    (pseudoTemplate: PseudoTemplate, position: number) => {
      setSelectedPseudoTemplateId(pseudoTemplate.id)
      recordDidSelectPodTemplate({
        podTemplateId: pseudoTemplate.id,
        podTemplatePosition: position,
      })
      navigate('edit')
    },
    [navigate, recordDidSelectPodTemplate]
  )

  const onEditCompleted: PhotoProductEditCompleteCallback = useCallback(
    async ({ printAreas, sourceImageDataUrls, variantId, photoUrl, isPristine }) => {
      recordDidTapPreview({
        providerProductId,
      })

      navigate('preview')

      if (isPristine) {
        return
      }

      await generatePreview({
        variant: variantId ? variantsById[variantId] : variant,
        sourceImageDataUrls,
        printAreas:
          printAreas ?? printAreaPositions.map((position) => ({ position, imageUrl: photoUrl })),
      })
    },
    [generatePreview, navigate, recordDidTapPreview, providerProductId, variant]
  )

  const onPreviewCompleted = useCallback(
    async (options: { isSkippingPreview: boolean }) => {
      setIsAddingItemToCart(true)

      if (options.isSkippingPreview) {
        recordDidSkipPreview({
          providerProductId,
        })
      }

      const payload = await createPayloadWithVariant(variant)
      const result = addItemToCartPayloadSchema.safeParse({
        ...payload,
        metadata: {
          ...payload.metadata,
          designTemplateId: selectedTemplate?.id,
          pseudoTemplateId: selectedPseudoTemplateId,
        },
      })

      if (result.success) {
        await addItemToCart(result.data)
        resetState()
        setIsAddingItemToCart(false)
      } else {
        console.error(result.error)
      }
    },
    [providerProductId, createPayloadWithVariant, resetState, variant, selectedPseudoTemplateId]
  )

  const onProductSelectionCompleted = useCallback(
    (payload: { variantId: number }, clickedElement: string) => {
      updateVariantId(payload.variantId)
      recordDidTapProductPageC2A({
        providerProductId,
        clickedElement,
      })

      if (hasDesignTemplates || photoProduct.category === 'ORNAMENTS') {
        navigate('select-template')
      } else {
        navigate('edit')
      }
    },
    [navigate, updateVariantId, recordDidTapProductPageC2A, providerProductId, hasDesignTemplates]
  )

  // useEffect(() => {
  //   getCuratedPhotos()
  // }, [getCuratedPhotos])

  const showPreview = sourceImagesDataUrls.length > 0 && !isUsingFallback
  const sourceImages = isUsingFallback && !sourceImagesDataUrls.length ? [] : photoUrls
  const editScreen = determineEditScreen(photoProduct)
  const isEditMode = !!editedCartItem
  const { orderedTemplateIds, orderedPseudoTemplateIds } = useMemo(
    () => ({
      orderedTemplateIds:
        photoProduct.category === 'ORNAMENTS' ? [] : ORDERED_TEMPLATE_IDS[photoProduct.category],
      orderedPseudoTemplateIds:
        photoProduct.category === 'ORNAMENTS'
          ? ORDERED_PSEUDO_TEMPLATE_IDS[photoProduct.category]
          : [],
    }),
    [photoProduct.category]
  )
  const content = isPreparingEdit ? (
    <LoadingOverlay />
  ) : (
    <>
      <Routes>
        <Route
          index
          element={
            <ProductPage
              onMount={resetState}
              carouselImages={carouselImages}
              productImages={productImages}
              openTermsOfUsePage={openTermsOfUsePage}
              price={formattedPrices.price}
              salePrice={formattedPrices.salePrice}
              product={photoProduct}
              variantTitle={title}
              variants={variants}
              initialVariantId={variantId}
              next={onProductSelectionCompleted}
              previous={navigateToSource}
              updateVariantId={updateVariantId}
            />
          }
        />
        <Route
          path="select-template"
          element={
            photoProduct.category === 'ORNAMENTS' ? (
              <SelectPseudoTemplatePage
                orderedTemplateIds={orderedPseudoTemplateIds}
                cartHeader={cartHeader('Select a Design')}
                variantId={variantId}
                previous={() => navigate('.')}
                curatedPhoto={null}
                arePhotosLoaded={arePhotosLoaded}
                selectPseudoTemplate={onPseudoTemplateSelected}
                pseudoTemplates={getPseudoTemplatesForVariant(variantId)}
              />
            ) : (
              <SelectTemplatePage
                variantId={variantId}
                orderedTemplateIds={orderedTemplateIds}
                cartHeader={cartHeader('Select a Design')}
                showPreview={showPreview}
                previous={() => navigate('.')}
                templateWidth={variant.width}
                templateHeight={variant.height}
                curatedPhotos={curatedPhotos}
                arePhotosLoaded={arePhotosLoaded}
                selectTemplate={onTemplateSelected}
                designTemplates={photoProduct.designTemplates}
              />
            )
          }
        />

        <Route
          path="edit"
          element={
            editScreen === EditScreen.TEMPLATE ? (
              <EditTemplatePage
                isEditMode={isEditMode}
                templateWidth={variantWidth}
                templateHeight={variantHeight}
                template={selectedTemplate as DesignTemplate}
                sourceImages={sourceImages}
                previous={() => {
                  navigate('select-template')
                }}
                next={onEditCompleted}
              />
            ) : editScreen === EditScreen.SINGLE_PHOTO ? (
              <SinglePhotoEdit
                isPreparingEdit={isPreparingEdit}
                variant={variant}
                sourceImages={sourceImagesDataUrls}
                previous={() => navigate('.')}
                isEditMode={isEditMode}
                cancelCartItemEdit={cancelCartItemEdit}
                variants={variants}
                currency={currency}
                next={onEditCompleted}
              />
            ) : editScreen === EditScreen.HEAD_SEGMENTATION ? (
              <HeadSegmentationEdit
                isEditMode={isEditMode}
                template={selectedTemplate as DesignTemplate}
                templateWidth={variantWidth}
                templateHeight={variantHeight}
                isPreparingEdit={isPreparingEdit}
                variant={variant}
                sourceImages={sourceImagesDataUrls}
                previous={() => navigate('select-template')}
                cancelCartItemEdit={cancelCartItemEdit}
                product={photoProduct}
                variants={variants}
                currency={currency}
                next={onEditCompleted}
              />
            ) : editScreen === EditScreen.BACK_FRONT_PSEUDO_TEMPLATE ? (
              <BackFrontPseudoTemplateEdit
                isPreparingEdit={isPreparingEdit}
                isEditMode={isEditMode}
                pseudoTemplate={selectedPseudoTemplate as PseudoTemplate}
                templateWidth={selectedPseudoTemplate?.templateWidth ?? 0}
                templateHeight={selectedPseudoTemplate?.templateHeight ?? 0}
                frontSideSourceImages={{
                  original: sourceImages[0]?.original,
                  cropped: sourceImages[0]?.cropped,
                }}
                previous={() => navigate('select-template')}
                next={onEditCompleted}
              />
            ) : null
          }
        />
        <Route
          path="preview"
          element={
            <PreviewPage
              canSkipPreview={canSkipPreview}
              isGettingPreview={isGettingPreview}
              isAddingItemToCart={isAddingItemToCart}
              isPrintifyPreviewLogsActive={isPrintifyPreviewLogsActive}
              openLogsPage={() => setIsLogsPageVisible(true)}
              showNotAllLoadedError={showNotAllLoadedError}
              previewPhotosList={previewPhotosList}
              previous={() => navigate('edit')}
              next={onPreviewCompleted}
              regeneratePreview={regeneratePreview}
            />
          }
        />
      </Routes>
      {isPrintifyPreviewLogsActive && (
        <LogsPanel
          logs={fetchLogs}
          isOpen={isLogsPageVisible}
          close={() => setIsLogsPageVisible(false)}
        />
      )}
    </>
  )

  return content
}
