import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
  const topRef = useRef<HTMLDivElement>(null)
  const { pathname } = useLocation()

  useEffect(() => {
    setTimeout(() => {
      topRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }, [pathname])

  return <div ref={topRef} />
} 