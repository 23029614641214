import cx from 'classnames'
import RebateBadge from './RebateBadge'
import { buttonCommonClassName, buttonVariants } from './buttons/config'
export default function PhotoGiftProductCard({
  src,
  text,
  rebate,
}: {
  src: string
  text: string
  rebate?: string
}) {
  return (
    <div className="flex flex-col items-center bg-gray-2 rounded-lg shadow-photo-product-card">
      <div className="relative h-[120px]">
        <img src={src} alt={text} className="h-full mw-auto" />
        {rebate && <RebateBadge rebate={rebate} className="absolute top-2 right-0" />}
      </div>
      <div className="bg-white pb-2 rounded-b-lg flex flex-col h-[80px] text-center text-black font-normal w-full pt-2">
        <p>{text}</p>
        <div
          role="button"
          className={cx(
            buttonVariants.secondary,
            'rounded-full text-15 font-bold w-20 py-0.5 mx-auto mt-auto'
          )}
        >
          Create
        </div>
      </div>
    </div>
  )
}
