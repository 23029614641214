import { useCallback, useRef, useState } from 'react'
import { CuratedPhotoForPhotoProducts } from '../../../clients/fagl-server/types'

type FrameSizeRelativeNumbers = {
  left: number
  top: number
  width: number
  height: number
  rotate: number
}

const WIDTH = 375
const HEIGHT = 337
const FRAME1 = {
  left: (23.23 / WIDTH) * 100,
  top: (212.8 / HEIGHT) * 100, // 64.5, <- for after the $20 off sale is over
  width: (60.7 / WIDTH) * 100,
  height: (67.29 / HEIGHT) * 100,
  rotate: -5.22,
}

const FRAME2 = {
  left: (149.75 / WIDTH) * 100,
  top: (205.94 / HEIGHT) * 100,
  width: (63.58 / WIDTH) * 100,
  height: (85.59 / HEIGHT) * 100,
  rotate: 4.95,
}

const FRAME3 = {
  left: (263.16 / WIDTH) * 100,
  top: (200.78 / HEIGHT) * 100,
  width: (67.25 / WIDTH) * 100,
  height: (85.59 / HEIGHT) * 100,
  rotate: -67.25,
}

const FRAME3_CURATED = {
  left: (263.16 / WIDTH) * 100,
  top: (202.78 / HEIGHT) * 100,
  width: (67.25 / WIDTH) * 100,
  height: (85.59 / HEIGHT) * 100,
  rotate: 2.95,
}

export default function CuratedPhotosHeader({
  staticFileFolder,
  curatedPhotos,
  arePhotosForPhotoProductsLoaded,
  isUsingFallback,
}: {
  staticFileFolder: string
  curatedPhotos: CuratedPhotoForPhotoProducts[]
  arePhotosForPhotoProductsLoaded: boolean
  isUsingFallback: boolean
}) {
  const [boundingBox, setBoundingBox] = useState<DOMRect | null>(null)

  const imageRef = useRef<HTMLImageElement>(null)
  const getFrameSizeAbsolute = useCallback(
    (frameSizeRelativeNumbers: FrameSizeRelativeNumbers) => {
      if (!boundingBox)
        return {
          width: 0,
          height: 0,
          top: 0,
          left: 0,
        }
      return {
        width: (boundingBox.width * frameSizeRelativeNumbers.width) / 100,
        height: (boundingBox.height * frameSizeRelativeNumbers.height) / 100,
        top: (boundingBox.height * frameSizeRelativeNumbers.top) / 100,
        left: (boundingBox.width * frameSizeRelativeNumbers.left) / 100,
      }
    },
    [boundingBox]
  )

  const urlsToUse =
    arePhotosForPhotoProductsLoaded && isUsingFallback
      ? []
      : curatedPhotos.map((photo) => photo.croppedUrl)

  if (urlsToUse.length < 3) {
    return (
      <header className="relative">
        <img src={`${staticFileFolder}/header-static.jpg`} className="w-full h-full object-cover" />
      </header>
    )
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    swipeToSlide: true,
    arrows: false,
    fade: true,
  }

  return (
    <header className="relative">
      <div
        className="absolute overflow-hidden bg-white z-0"
        style={{
          ...getFrameSizeAbsolute(FRAME1),
          transform: `rotate(${FRAME1.rotate}deg)`,
        }}
      >
        <img src={urlsToUse[0]} className="w-full h-full object-cover" />
      </div>
      <div
        className="absolute overflow-hidden bg-white z-0"
        style={{
          ...getFrameSizeAbsolute(FRAME2),
          transform: `rotate(${FRAME2.rotate}deg)`,
        }}
      >
        <img src={urlsToUse[1]} className="w-full h-full object-cover" />
      </div>
      <div
        className="absolute overflow-hidden bg-white z-0"
        style={{
          ...getFrameSizeAbsolute(curatedPhotos.length > 0 ? FRAME3_CURATED : FRAME3),
          transform: `rotate(${
            curatedPhotos.length > 0 ? FRAME3_CURATED.rotate : FRAME3.rotate
          }deg)`,
        }}
      >
        <img src={urlsToUse[2]} className="w-full h-full object-cover" />
      </div>
      <img
        src={`${staticFileFolder}/header-cutout.png`}
        ref={imageRef}
        className="relative z-10"
        onLoad={(e) => {
          setBoundingBox((e.target as HTMLImageElement).getBoundingClientRect())
        }}
      />
    </header>
  )
}
