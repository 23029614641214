import { Fragment, useEffect, useState } from 'react'
import cx from 'classnames'
import Spinner from '../../../shared/Spinner'
import { PreviewUrlState } from '../hooks/usePreview'

export default function ProductPhotos({ photos }: { photos: PreviewUrlState[] }) {
  const [selectedPreviewIndex, setSelectedPreviewIndex] = useState(0)
  useEffect(() => {
    const defaultIndex = photos.findIndex((photo) => photo.isDefault)

    setSelectedPreviewIndex(defaultIndex)
  }, [photos])

  return (
    <div>
      <div className="w-full rounded-lg relative mb-4">
        {photos.map((mainPreview, index) => (
          <Fragment key={mainPreview.url + mainPreview.isPreviewReady}>
            <img
              className={cx('rounded-lg w-full min-h-[398px] transition-opacity', {
                'opacity-0': index !== selectedPreviewIndex,
                'blur-sm': !mainPreview?.isPreviewReady && !mainPreview?.base64,
                'absolute top-0 left-0': index !== selectedPreviewIndex,
              })}
              src={mainPreview?.base64}
            />
            {!mainPreview?.isPreviewReady && !mainPreview?.possiblySucceededOnFirstLoad && (
              <div className="absolute flex top-0 left-0 w-full h-full items-center justify-center">
                <Spinner className="w-8" />
              </div>
            )}
          </Fragment>
        ))}
      </div>
      <ul className="grid grid-cols-4 gap-2">
        {photos
          .slice(0, 4)
          .map(({ url, base64, isPreviewReady, possiblySucceededOnFirstLoad }, index) => (
            <li
              key={url + isPreviewReady}
              className={cx('rounded-lg bg-white ring-fa-primary transition-all overflow-hidden', {
                'ring-2': selectedPreviewIndex === index,
                'ring-0': selectedPreviewIndex !== index,
              })}
            >
              <button
                className="relative w-full h-full min-h-[94px] min-w-[94px]"
                onClick={() => setSelectedPreviewIndex(index)}
              >
                <img
                  className={cx('rounded-lg w-[105%] h-[110%] object-cover', {
                    'blur-sm': !isPreviewReady && !base64,
                  })}
                  src={base64}
                />
                {!isPreviewReady && !possiblySucceededOnFirstLoad && (
                  <div className="absolute flex top-0 left-0 w-full h-full items-center justify-center">
                    <Spinner className="w-6" />
                  </div>
                )}
              </button>
            </li>
          ))}
      </ul>
    </div>
  )
}
