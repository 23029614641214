import { createStorefrontApiClient, StorefrontApiClient } from '@shopify/storefront-api-client'
import {
  ADD_LINE_ITEM_TO_CART_MUTATION,
  CREATE_CART_MUTATION,
  CREATE_CART_MUTATION_WITH_COUPON_CODE,
  FETCH_CART_QUERY,
  PRODUCT_BY_HANDLE_QUERY,
  REMOVE_LINE_ITEM_FROM_CART_MUTATION,
  UPDATE_CART_ATTRIBUTES_MUTATION,
  UPDATE_LINE_ITEM_IN_CART_MUTATION,
} from './queries'
import { Variant } from './types'

const API_VERSION = '2024-01'

export interface StorefrontClientConfig {
  storeDomain: string
  publicAccessToken: string
  productHandle: string
}

export class StorefrontClient {
  client: StorefrontApiClient
  productHandle: string

  constructor(config: StorefrontClientConfig) {
    this.client = createStorefrontApiClient({
      storeDomain: config.storeDomain,
      apiVersion: API_VERSION,
      publicAccessToken: config.publicAccessToken,
    })
    this.productHandle = config.productHandle
  }

  getUnitPrice(variant: Variant) {
    let unitPrice = ''
    if (variant.title === 'Loop Single') {
      unitPrice = parseFloat(variant.price.amount).toFixed(2)
    } else if (variant.title === 'Loop 2 Pack') {
      unitPrice = (parseFloat(variant.price.amount) / 2).toFixed(2)
    } else if (variant.title === 'Loop 3 Pack') {
      unitPrice = (parseFloat(variant.price.amount) / 3).toFixed(2)
    } else if (variant.title === 'Loop 4 Pack') {
      unitPrice = (parseFloat(variant.price.amount) / 4).toFixed(2)
    }

    return {
      amount: unitPrice,
      currencyCode: variant.price.currencyCode,
    }
  }

  getDiscountedPercentage(variant: Variant) {
    const price = parseFloat(variant.price.amount)
    const compareAtPrice = parseFloat(variant.compareAtPrice.amount)
    const discount = ((compareAtPrice - price) / compareAtPrice) * 100
    return Math.round(discount)
  }

  parseProduct(data: any) {
    const product = data.product || data

    return {
      id: product.id,
      handle: product.handle,
      title: product.title,
      variants: product.variants.nodes.map((variant: any) => ({
        id: variant.id,
        title: variant.title,
        compareAtPrice: variant.compareAtPrice,
        price: variant.price,
        unitPrice: this.getUnitPrice(variant),
        discountedPercentage: this.getDiscountedPercentage(variant),
      })),
    }
  }

  async getProduct() {
    const { data } = await this.client.request(PRODUCT_BY_HANDLE_QUERY, {
      variables: {
        handle: this.productHandle,
      },
    })

    return this.parseProduct(data)
  }

  async getCart(cartId: string) {
    const { data } = await this.client.request(FETCH_CART_QUERY, {
      variables: { cartId },
    })

    return data.cart
  }

  async createCartWithCouponCode(couponCode: string) {
    const { data } = await this.client.request(CREATE_CART_MUTATION_WITH_COUPON_CODE, {
      variables: {
        couponCode,
      },
    })

    return data.cartCreate.cart
  }

  async createCart() {
    const { data } = await this.client.request(CREATE_CART_MUTATION)

    return data.cartCreate.cart
  }

  async addLineItem(cartId: string, variantId: string, quantity: number) {
    const { data } = await this.client.request(ADD_LINE_ITEM_TO_CART_MUTATION, {
      variables: {
        cartId,
        variantId,
        quantity,
      },
    })

    return data.cartLinesAdd.cart
  }

  async removeLineItem(cartId: string, lineId: string) {
    const { data } = await this.client.request(REMOVE_LINE_ITEM_FROM_CART_MUTATION, {
      variables: {
        cartId,
        lineId,
      },
    })

    return data.cartLinesRemove.cart
  }

  async updateLineItem(cartId: string, lines: { id: string; quantity: number }[]) {
    const { data } = await this.client.request(UPDATE_LINE_ITEM_IN_CART_MUTATION, {
      variables: {
        cartId,
        lines,
      },
    })

    return data.cartLinesUpdate.cart
  }

  async updateCartAttributes(cartId: string, attributes: { key: string; value: string }[]) {
    const { data } = await this.client.request(UPDATE_CART_ATTRIBUTES_MUTATION, {
      variables: {
        cartId,
        attributes,
      },
    })

    return data.cartAttributesUpdate.cart
  }
}
