import { useCallback, useState } from 'react'
import { PhotoProduct } from '../../../clients/fagl-server/types/photoProducts'
import { useApi } from '../../../hooks/useApi'
import { isAxiosError } from 'axios'
import { useRollbar } from '@rollbar/react'

const NUMBER_OF_UNIQUE_TEMPLATE_SECTION_IMAGES = 6
const NUMBER_OF_PHOTO_PRODUCT_MAIN_GALLERY_IMAGES = 4

function createMainGalleryPhotoUrlsForProductId(
  provider: string,
  providerProductId: number,
  number: number
) {
  const urls = []
  for (let i = 1; i <= number; i++) {
    urls.push(
      `/photo-products/products/${provider}/${providerProductId}/product-page-gallery/${i}.jpg`
    )
  }
  return urls
}

function createUniqueTemplatePhotoUrlsForProductId(
  provider: string,
  providerProductId: number,
  number: number
) {
  const urls = []
  for (let i = 1; i <= number; i++) {
    urls.push(
      `/photo-products/products/${provider}/${providerProductId}/product-page-carousel/${i}.png`
    )
  }
  return urls
}

export function usePhotoProduct() {
  const { api } = useApi()
  const rollbar = useRollbar()
  const [isLoading, setIsLoading] = useState(true)
  const [photoProduct, setPhotoProduct] = useState<PhotoProduct | null>(null)
  const [productImages, setProductImages] = useState<string[]>([])
  const [carouselImages, setCarouselImages] = useState<string[]>([])
  const [loadError, setLoadError] = useState<string>('')
  const variants = photoProduct?.variants || []

  const load = useCallback(
    async (id: number) => {
      setLoadError('')
      try {
        const response = await api.photoProducts.getPhotoProductAndVariants(id)
        setPhotoProduct(response)
        setProductImages(
          createMainGalleryPhotoUrlsForProductId(
            'PRINTIFY',
            id,
            NUMBER_OF_PHOTO_PRODUCT_MAIN_GALLERY_IMAGES
          )
        )
        setCarouselImages(
          createUniqueTemplatePhotoUrlsForProductId(
            'PRINTIFY',
            id,
            NUMBER_OF_UNIQUE_TEMPLATE_SECTION_IMAGES
          )
        )
      } catch (err) {
        const error = err as Error
        if (isAxiosError(err)) {
          if (err.response?.status === 404) {
            setLoadError('NOT_FOUND')
          }
        } else {
          console.error(err)
          rollbar.error('unexpected error loading photo product', error)
          setLoadError(error.message)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [photoProduct, setPhotoProduct]
  )

  return {
    isLoading,
    photoProduct,
    loadError,
    load,
    variants,
    productImages,
    carouselImages,
  }
}
