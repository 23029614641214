import {
  VariantColorOption,
  VariantShapeOption,
  VariantSizeOption,
} from '../../../clients/fagl-server/types/photoProducts'
import VariantSelectButton from './VariantSelectButton'
import VariantGenericOptionSelector from './VariantGenericOptionSelector'

export default function VariantSelector({
  title,
  option,
  selectedVariantId,
  updateVariantId,
  additionalInfo,
  colorOptions = [],
  sizeOptions = [],
  shapeOptions = [],
}: {
  title: string
  option: 'size' | 'color' | 'shape'
  selectedVariantId: number
  updateVariantId: (variantId: number) => void
  additionalInfo?: string
  colorOptions?: {
    variantId: number
    option: VariantColorOption
  }[]
  sizeOptions?: {
    variantId: number
    option: VariantSizeOption
  }[]
  shapeOptions?: {
    variantId: number
    option: VariantShapeOption
  }[]
}) {
  return (
    <div className="space-x-1 space-y-2">
      <div className="flex items-baseline">
        <h2 className="font-bold">{title}</h2>
        {additionalInfo && <span className="text-gray-11 text-s ml-1">({additionalInfo})</span>}
      </div>
      {option === 'size' && (
        <VariantGenericOptionSelector
          options={sizeOptions}
          label="Size:"
          updateVariantId={updateVariantId}
          selectedVariantId={selectedVariantId}
        />
      )}
      {option === 'shape' && (
        <VariantGenericOptionSelector
          label="Shape:"
          options={shapeOptions}
          updateVariantId={updateVariantId}
          selectedVariantId={selectedVariantId}
        />
      )}
      {option === 'color' && (
        <ul className="flex flex-wrap gap-3">
          {colorOptions
            // TODO: Revert this - Remove the variantId 96186 RED - TEMPORARY
            .filter(({ variantId }) => variantId !== 96186)
            .map(({ variantId, option: { value, hex } }) => {
              const isSelected = selectedVariantId === variantId
              return (
                <li key={variantId}>
                  <VariantSelectButton
                    isSelected={isSelected}
                    colorName={value}
                    colorValue={hex}
                    variantId={variantId}
                    updateVariantId={updateVariantId}
                  />
                </li>
              )
            })}
        </ul>
      )}
    </div>
  )
}
