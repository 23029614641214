import { AxiosInstance } from 'axios'
import {
  PhotoProduct,
  GetPhotoProductAndVariants,
  GetImagesForPreview,
  GetImagesForPreviewResponse,
  GetHashesForBaseImages,
} from './types/photoProducts'

export class PhotoProductsApi {
  api: AxiosInstance

  constructor(api: AxiosInstance) {
    this.api = api
  }

  getPhotoProductAndVariants: GetPhotoProductAndVariants = async (photoProductId: number) => {
    const { data } = await this.api.get<PhotoProduct>(`/v1/photo-products/${photoProductId}`)

    return data
  }

  getHashesForBaseImages: GetHashesForBaseImages = async (variantId: number) => {
    const { data } = await this.api.get<string[]>(`/v1/printify/baseImageHash/${variantId}`)
    return data
  }
  getImagesForPreview: GetImagesForPreview = async (payload) => {
    const {
      data: { productId, images },
    } = await this.api.post<GetImagesForPreviewResponse>(`/v1/printify/generate-preview`, payload)

    return {
      images,
      productId,
    }
  }
}
