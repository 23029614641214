import { useCallback, useEffect, useState } from 'react'
import { PhotoProduct, Variant } from '../../../clients/fagl-server/types/photoProducts'
import ProductPhotos from '../components/ProductPhotos'
import VariantSelectionSection from '../components/VariantSelectionSection'
import ProductPageUniqueTemplates from './ProductPageUniqueTemplates'
import ProductPageFooter from './ProductPageFooter'
import ProductPageDetails from './ProductPageDetailts'
import ProductPageHeader from './ProductPageHeader'

export default function ProductPage({
  next,
  previous,
  product,
  variants,
  initialVariantId,
  price,
  salePrice,
  openTermsOfUsePage,
  updateVariantId,
  variantTitle,
  productImages,
  carouselImages,
  onMount,
}: {
  next: (payload: { variantId: number }, clickedElement: string) => void
  previous: () => void
  product: PhotoProduct
  variants: Variant[]
  initialVariantId: number
  price: string
  variantTitle: string
  salePrice: string
  productImages: string[]
  carouselImages: string[]
  openTermsOfUsePage: () => void
  onMount: () => void
  updateVariantId: (variantId: number) => void
}) {
  const [variantId, setVariantId] = useState<number>(initialVariantId)

  const onNextClick = useCallback(
    (clickedElement: string) => {
      if (!variantId) {
        return
      }
      next({ variantId }, clickedElement)
    },
    [variantId, next]
  )

  const onVariantIdChange = useCallback(
    (variantId: number) => {
      setVariantId(variantId)
      updateVariantId(variantId)
    },
    [updateVariantId]
  )

  useEffect(() => {
    onMount()
  }, [])

  return (
    <div className="h-screen relative flex flex-col pt-4">
      <main className="flex-1 px-4 pb-8 space-y-4">
        <ProductPhotos
          photos={productImages.map((url, index) => ({
            isDefault: index === 0,
            isPreviewReady: true,
            isDoneTrying: true,
            url,
            base64: url,
            possiblySucceededOnFirstLoad: false,
          }))}
        />
        <ProductPageHeader variantTitle={variantTitle} price={price} salePrice={salePrice} />
        <VariantSelectionSection
          variants={variants}
          selectedVariantId={variantId}
          updateVariantId={onVariantIdChange}
        />
        <ProductPageDetails itemDetails={product.itemDetails} keyFeatures={product.keyFeatures} />
      </main>
      <ProductPageUniqueTemplates carouselImages={carouselImages} onNextClick={onNextClick} />
      <ProductPageFooter
        openTermsOfUsePage={openTermsOfUsePage}
        previous={previous}
        onNextClick={() => onNextClick('footer')}
      />
    </div>
  )
}
