import React from 'react'

interface TemplateCardProps {
  children: React.ReactNode
  name: string
}

export default function TemplateCard({ children, name }: TemplateCardProps) {
  return (
    <div className="flex flex-col items-center bg-gray-2 rounded-lg shadow-photo-product-card">
      <div className="relative">{children}</div>
      <div className="bg-white pb-2 rounded-b-lg flex flex-col font-normal text-center text-black w-full pt-2">
        <p>{name}</p>
      </div>
    </div>
  )
}
