import { Suspense, useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { useSearchParam } from 'react-use'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import AdvocateLanding from './pages/referral/advocate/AdvocateLanding'
import ApiClient from './ApiClient'
import Redeem from './pages/referral/referred/Redeem'
import RedeemLanding from './pages/referral/referred/RedeemLanding'
import AdvocateRedemptionPage from './pages/referral/advocate/AdvocateRedemptionPage'
import { Toaster } from 'react-hot-toast'
import config from './config'
import RedeemLandingNoReward from './pages/referral/referred/RedeemLandingNoReward'
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react' // Provider imports 'rollbar'
import Loop from './pages/loop/Loop'
import LoopAuth from './pages/loop/LoopAuth'
import { b64Encode, isMaintenanceTimeInEST, uniqueId } from './utils'
import MaintenancePage from './pages/MaintenancePage'
import { lazy } from './shared/ReactLazyWithReload'
import LoadingScreen from './shared/LoadingScreen'
import { StorefrontClient } from './clients/storefront'
import Clarity from './shared/vendor/Clarity'
import PhotoProductsApp from './pages/photo-products'
import ErrorPage from './pages/ErrorPage'
import { ApiProvider } from './hooks/useApi'
import PhotoProductsFallbackUi from './pages/photo-products/PhotoProductsFallbackUi'
import './index.css'

const api = new ApiClient({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  useFaPhotoProxy: import.meta.env.VITE_USE_FA_PHOTO_PROXY === 'true',
})

const storefrontClient = new StorefrontClient({
  storeDomain: import.meta.env.VITE_STORE_DOMAIN,
  publicAccessToken: import.meta.env.VITE_STOREFRONT_PUBLIC_ACCESS_TOKEN,
  productHandle: import.meta.env.VITE_STORE_PRODUCT_HANDLE,
})

const rollbarConfig = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  environment: import.meta.env.VITE_ENVIRONMENT,
}

const {
  familyAlbumAutoLoginUrl,
  familyAlbumDownloadDeepLink,
  supportEmail,
  familyAlbumStoreDeepLink,
  clarityProjectId,
  isPrintifyPreviewLogsActive,
} = config

const generateReferredUserAutologinUrl = (advocateReferralCode: string) => {
  return `${familyAlbumAutoLoginUrl}?data=${b64Encode(JSON.stringify({ advocateReferralCode }))}`
}

const generateLoopAutologinUrl = (loopUserId: string) => {
  return `${familyAlbumAutoLoginUrl}?data=${b64Encode(JSON.stringify({ loopUserId }))}`
}

const GPSTalk = lazy(
  () => import(/* webpackChunkName: "GPSTalk" */ './pages/smokescreen-tests/GPSTalk')
)

const Survey = lazy(
  () => import(/* webpackChunkName: "Survey" */ './pages/smokescreen-tests/Survey')
)

const SurveyThankYouJp = lazy(
  () =>
    import(/* webpackChunkName: "SurveyThankYouJp" */ './pages/smokescreen-tests/SurveyThakYouJp')
)

const SurveyThankYou = lazy(
  () => import(/* webpackChunkName: "SurveyThankYou" */ './pages/smokescreen-tests/SurveyThankYou')
)

const LoopMothersDay = lazy(
  () => import(/* webpackChunkName: "LoopMothersDay" */ './pages/loop/LoopMothersDay')
)

const LoopFathersDay = lazy(
  () => import(/* webpackChunkName: "LoopFathersDay" */ './pages/loop/LoopFathersDay')
)

const LoopGrandparentsDay = lazy(
  () => import(/* webpackChunkName: "LoopGrandparentsDay" */ './pages/loop/LoopGrandparentsDay')
)

const PhotoProductsTou = lazy(
  () => import(/* webpackChunkName: "PhotoProductsTou" */ './pages/photo-products/PhotoProductsTou')
)

const GcTerms = lazy(() => import(/* webpackChunkName: "GcTerms" */ './pages/referral/GcTerms'))

const LoopModal = lazy(
  () => import(/* webpackChunkName: "LoopModal" */ './pages/loop/landing/index')
)
const LoopStore = lazy(() => import(/* webpackChunkName: "LoopStore" */ './pages/loop/LoopStore'))

const router = createBrowserRouter([
  {
    path: '/photo-products/*',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <ErrorBoundary fallbackUI={PhotoProductsFallbackUi}>
          <PhotoProductsApp
            supportEmail={supportEmail}
            isPrintifyPreviewLogsActive={isPrintifyPreviewLogsActive}
          />
        </ErrorBoundary>
      </Suspense>
    ),
  },
  {
    path: '/familyalbum-gps/*',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Survey surveyId="XJMKWKX">
          <GPSTalk />
        </Survey>
      </Suspense>
    ),
  },
  {
    path: '/user-needs-survey/*',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Survey surveyId="LHN2HKK" />
      </Suspense>
    ),
  },
  {
    path: '/family-priorities-survey/*',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Survey surveyId="D22VZ6T" />
      </Suspense>
    ),
  },
  {
    path: '/survey/thank-you',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <SurveyThankYou
          title="Your feedback has been submitted successfully"
          message="Thank you for completing the survey and helping us improve our services. Your feedback is incredibly valuable to us, and we truly appreciate your time."
        />
      </Suspense>
    ),
  },
  {
    path: '/survey/thank-you-ss',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <SurveyThankYou
          title="Thanks for your interest!"
          message="Thank you for submitting your responses! We’ll keep you updated with the
        latest news and developments."
        />
      </Suspense>
    ),
  },
  {
    path: '/survey/thank-you-jp',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <SurveyThankYouJp />
      </Suspense>
    ),
  },
  {
    path: '/loop/auth',
    element: <LoopAuth />,
  },
  {
    path: '/loop',
    element: <Loop generateLoopAutologinUrl={generateLoopAutologinUrl} />,
  },
  {
    path: '/modal-loop-holidays',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <LoopModal
          // couponCode="FAMILYALBUM"
          variant="HOLIDAY"
          api={api}
          supportEmail={supportEmail}
        />
      </Suspense>
    ),
  },
  {
    path: '/modal-loop',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <LoopModal
          // TODO: Re-add coupon code after holidays (2024-12-26)
          // couponCode="FAMILYALBUM"
          api={api}
          supportEmail={supportEmail}
        />
      </Suspense>
    ),
  },
  {
    path: '/modal-loop-new-user',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <LoopModal
          // TODO: Re-add coupon code after holidays (2024-12-26)
          // couponCode="FAMILYALBUM"
          api={api}
          supportEmail={supportEmail}
        />
      </Suspense>
    ),
  },
  {
    path: '/loop-store/*',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <LoopStore storefrontClient={storefrontClient} />
      </Suspense>
    ),
  },
  {
    path: '/modal-loop-mothers-day',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <LoopMothersDay api={api} supportEmail={supportEmail} />
      </Suspense>
    ),
  },
  {
    path: '/modal-loop-fathers-day',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <LoopFathersDay api={api} supportEmail={supportEmail} couponCode="HAPPYDAD" />
      </Suspense>
    ),
  },
  {
    path: '/modal-loop-grandparents-day',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <LoopGrandparentsDay api={api} supportEmail={supportEmail} couponCode="GRANDJOY" />
      </Suspense>
    ),
  },
  {
    path: '/photo-gifts',
    element: <Navigate to="/photo-products" replace />,
  },
  {
    path: '/refer/:advocateReferralCode',
    element: (
      <AdvocateLanding
        supportEmail={supportEmail}
        familyAlbumDownloadDeepLink={familyAlbumDownloadDeepLink}
        api={api}
      />
    ),
  },
  {
    path: '/advocate/:advocateReferralCode/redemptions/:redemptionId',
    element: (
      <AdvocateRedemptionPage
        supportEmail={supportEmail}
        familyAlbumStoreDeepLink={familyAlbumStoreDeepLink}
        familyAlbumDownloadDeepLink={familyAlbumDownloadDeepLink}
        api={api}
      />
    ),
  },
  {
    path: '/advocate/:advocateReferralCode/redemptions/:redemptionId/past',
    element: (
      <AdvocateRedemptionPage
        supportEmail={supportEmail}
        isPastRedemtpion
        familyAlbumStoreDeepLink={familyAlbumStoreDeepLink}
        familyAlbumDownloadDeepLink={familyAlbumDownloadDeepLink}
        api={api}
      />
    ),
  },
  {
    path: '/redeem',
    element: (
      <Redeem
        supportEmail={supportEmail}
        api={api}
        familyAlbumStoreDeepLink={familyAlbumStoreDeepLink}
      />
    ),
  },
  {
    path: '/r/:advocateReferralCode',
    element: (
      <RedeemLanding
        generateReferredUserAutologinUrl={generateReferredUserAutologinUrl}
        familyAlbumDownloadDeepLink={familyAlbumDownloadDeepLink}
      />
    ),
  },
  {
    path: '/r0/:advocateReferralCode',
    element: (
      <RedeemLandingNoReward generateReferredUserAutologinUrl={generateReferredUserAutologinUrl} />
    ),
  },
  {
    path: 'referral/gc-terms/:currencyCode?',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <GcTerms />
      </Suspense>
    ),
  },
  {
    path: 'photo-products-tou',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <PhotoProductsTou />
      </Suspense>
    ),
  },
  {
    path: '*',
    element: <ErrorPage error={404} message="Page Not Found" />,
  },
])

function Root() {
  const market = useSearchParam('market')
  const uuid = useSearchParam('uuid')

  const [session] = useState({
    market,
    uuid,
    sid: uniqueId(),
  })

  useEffect(() => {
    if (!clarityProjectId) {
      console.log('No project ID provided for Clarity')
    }
  }, [])

  return (
    <RollbarProvider config={rollbarConfig}>
      {clarityProjectId && <Clarity projectId={clarityProjectId} />}
      <Toaster position="top-center" />
      <ApiProvider value={{ api, session }}>
        <RouterProvider router={router} />
      </ApiProvider>
    </RollbarProvider>
  )
}

if (isMaintenanceTimeInEST()) {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<MaintenancePage />)
} else {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<Root />)
}
