import FaqItem from '../../../shared/FaqItem'

export default function FaqSection({ supportEmail }: { supportEmail: string }) {
  const faqItems = [
    {
      question: 'Can I see a preview of my order?',
      answer:
        'Yes, all custom photo product orders with FamilyAlbum can be previewed before payment.',
    },
    {
      question: 'How long does it take to get my order?',
      answer:
        'Orders are typically delivered in 2 to 8 business days but shipping times may vary due to the shipping method you choose, seasonality, and more.',
    },
    {
      question: 'How much is shipping?',
      answer: (
        <>
          Shipping charges vary by product and location. Your shipping fees will be calculated on
          the checkout screen after you enter your shipping address.
          <br />
          Note: Photo Gifts are not eligible for free shipping for Premium users.
        </>
      ),
    },
    {
      question: 'What is your return policy?',
      answer: (
        <>
          This is a custom personalized product, therefore we do not offer refunds, returns, or
          exchanges except for orders with physical defects or damage. Damaged orders are eligible
          for a full refund within 30 days of delivery. Please reach out to{' '}
          <a className="text-fa-primary" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>{' '}
          with a photo of your purchase to request a refund.
        </>
      ),
    },
  ]

  return (
    <section className="px-4 bg-white mt-4">
      <h2 className="font-bold text-2xl text-center mb-6">FAQ</h2>
      <div className="text-fa-text-secondary space-y-8">
        {faqItems.map((item) => (
          <FaqItem key={item.question} question={item.question} answer={item.answer} />
        ))}
      </div>
    </section>
  )
}
