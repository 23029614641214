import { Transition } from '@headlessui/react'

export function BaseTransition({
  children,
  show,
  durationClass = 'duration-300',
}: {
  children: React.ReactNode
  show: boolean
  durationClass?: string
}) {
  return (
    <Transition
      show={show}
      enter={`transition-opacity ${durationClass}`}
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave={`transition-opacity ${durationClass}`}
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      as="div"
    >
      {children}
    </Transition>
  )
}
