import { useCallback, useEffect } from 'react'
import Panel from '../../../shared/Panel'
import useFamilyAlbumPhotos from './useFamilyAlbumPhotos'
import { FaglFamilyAlbumMediaFilter } from '../../../ApiClient'
import Album from './Album'
import Summary from './Summary'

const filterToName = {
  [FaglFamilyAlbumMediaFilter.ALL]: 'All',
  [FaglFamilyAlbumMediaFilter.FAVORITE]: 'Favorites',
  [FaglFamilyAlbumMediaFilter.RECOMMENDED]: 'Recommended',
}

export default function FamilyAlbumPhotosSelectionPanel({
  isOpen,
  onClose,
  next,
  numberOfPhotos,
}: {
  isOpen: boolean
  onClose: Function
  next: (payload: string[]) => void
  numberOfPhotos: number
}) {
  const {
    photoSummary,
    updateQuery,
    resetQuery,
    currentQuery,
    familyPhotos,
    selectedPhotos,
    togglePhotoSelection,
    convertToBase64,
    resetPhotos,
    loadMore,
    loadPhotosForFamilyId,
    hasNext,
    isLoadingFamilyPhotos,
    albumName,
    error,
  } = useFamilyAlbumPhotos(numberOfPhotos)

  const closePanel = useCallback(() => {
    resetPhotos()
    onClose()
  }, [onClose, resetPhotos])

  const onCategoryClick = useCallback(
    (familyAlbumFamilyId: number, filter: FaglFamilyAlbumMediaFilter) => {
      updateQuery({
        familyAlbumFamilyId,
        filter,
      })
      loadPhotosForFamilyId(
        {
          familyAlbumFamilyId,
          filter,
        },
        ''
      )
    },
    [loadPhotosForFamilyId, updateQuery]
  )

  const confirmAdd = async () => {
    closePanel()
    const photos = await convertToBase64()
    next(photos.map((photo) => photo.base64))
  }

  useEffect(() => {
    if (isOpen) {
      resetQuery()
    }
  }, [isOpen, resetQuery])

  const filterName = filterToName[currentQuery.filter]
  const remainingPhotosToAdd = numberOfPhotos - selectedPhotos.length
  return (
    <Panel isOpen={isOpen} close={closePanel} disableFullScreen>
      <div className="bg-fa-bg-secondary h-full overflow-auto" data-clarity-mask="True">
        {!currentQuery.familyAlbumFamilyId ? (
          <Summary
            closePanel={closePanel}
            onCategoryClick={onCategoryClick}
            photoSummary={photoSummary}
          />
        ) : (
          <Album
            isSingleSelectionMode={numberOfPhotos === 1}
            isLoadingFamilyPhotos={isLoadingFamilyPhotos}
            hasNext={hasNext}
            loadMore={() => loadMore()}
            albumName={albumName}
            title={filterName}
            photos={familyPhotos}
            selectedPhotos={selectedPhotos}
            onClose={closePanel}
            togglePhotoSelection={togglePhotoSelection}
            confirmAdd={confirmAdd}
            onBack={() => resetQuery()}
            nextLabel={remainingPhotosToAdd > 0 ? `Choose ${remainingPhotosToAdd} more` : 'Create'}
            isNextDisabled={remainingPhotosToAdd > 0}
          />
        )}
        {error && (
          <div className="p-2 border-fa-attention text-fa-attention space-y-2 text-s text-center border rounded m-4">
            <p>There was an error retrieving your FamilyAlbum photos.</p>
            <p>Please use the option to upload photos from your device.</p>
            <pre>{error}</pre>
          </div>
        )}
      </div>
    </Panel>
  )
}
