import { range } from 'lodash'
import {
  DesignTemplate,
  CellType,
  VariantCellPositionRelativeToTemplate,
} from './fagl-server/types'

const getName = (variantId: number, backsideId: number) => {
  const commonNames = {
    1: "Santa's Workshop",
    2: 'North Pole',
    3: 'Candy Cane',
    4: 'Flannel',
    5: 'Let It Snow',
    6: 'Ribbon Whimsy',
    7: 'Cozy Winter',
    8: 'Spruce Delight',
    9: 'Holly Jolly',
    10: 'Festive Fair',
    11: 'Solid Gold',
    12: 'Solid Red',
    13: 'Midnight Stars',
  }
  const names = {
    72963: {
      ...commonNames,
      14: 'Classic Tree',
    },
    72965: {
      ...commonNames,
      14: 'Classic Star',
    },
    72960: {
      ...commonNames,
      14: 'Classic Stocking',
    },
  } as const

  type VariantId = keyof typeof names
  type BacksideId = keyof (typeof names)[VariantId]

  return names[variantId as VariantId]?.[backsideId as BacksideId]
}

const getBacksideUrl = (variantId: number, backsideId: number) => {
  return `/photo-products/back-sides/${variantId}/${backsideId}.png`
}

const url = (variantId: number) => {
  return `pseudo_${variantId}.png`
}

const tree: DesignTemplate = {
  id: 72963,
  name: 'Tree',
  numberOfUserImages: 2,
  maskImageUrl: url(72963),
  thumbnailUrl: url(72963),
  productVariantIds: [72963],
  cells: [
    {
      imageData: null,
      templateVariantId: 72963,
      type: CellType.Image,
      patternData: null,
      absoluteCenterX: 1149 / 2 + 100,
      absoluteCenterY: 1844 - 1700 / 2,
      width: 1149,
      height: 1700,
      positionRelativeToTemplate: VariantCellPositionRelativeToTemplate.Beneath,
      id: 1,
      order: 0,
      zIndex: 0,
    },
    {
      imageData: null,
      templateVariantId: 72963,
      type: CellType.Image,
      patternData: null,
      absoluteCenterX: 2670 - 1149 / 2 - 100,
      absoluteCenterY: 1844 - 1700 / 2,
      width: 1149,
      height: 1700,
      positionRelativeToTemplate: VariantCellPositionRelativeToTemplate.Beneath,
      id: 2,
      order: 0,
      zIndex: 0,
    },
  ],
}

const star: DesignTemplate = {
  id: 72965,
  name: 'Star',
  numberOfUserImages: 2,
  maskImageUrl: url(72965),
  thumbnailUrl: url(72965),
  productVariantIds: [72965],
  cells: [
    {
      imageData: null,
      templateVariantId: 72965,
      type: CellType.Image,
      patternData: null,
      absoluteCenterX: 690,
      absoluteCenterY: 759.5,
      width: 1097,
      height: 1214,
      positionRelativeToTemplate: VariantCellPositionRelativeToTemplate.Beneath,
      id: 1,
      order: 0,
      zIndex: 0,
    },
    {
      imageData: null,
      templateVariantId: 72965,
      type: CellType.Image,
      patternData: null,
      absoluteCenterX: 1978,
      absoluteCenterY: 759.5,
      width: 1097,
      height: 1214,
      positionRelativeToTemplate: VariantCellPositionRelativeToTemplate.Beneath,
      id: 2,
      order: 0,
      zIndex: 0,
    },
  ],
}

const stocking: DesignTemplate = {
  id: 72960,
  name: 'Stocking',
  numberOfUserImages: 2,
  maskImageUrl: url(72960),
  thumbnailUrl: url(72960),
  productVariantIds: [72960],
  cells: [
    {
      imageData: null,
      templateVariantId: 72960,
      type: CellType.Image,
      patternData: null,
      absoluteCenterX: 725,
      absoluteCenterY: 1030,
      width: 1158,
      height: 1874,
      positionRelativeToTemplate: VariantCellPositionRelativeToTemplate.Beneath,
      id: 1,
      order: 0,
      zIndex: 0,
    },
    {
      imageData: null,
      templateVariantId: 72960,
      type: CellType.Image,
      patternData: null,
      absoluteCenterX: 1955,
      absoluteCenterY: 1030,
      width: 1158,
      height: 1874,
      positionRelativeToTemplate: VariantCellPositionRelativeToTemplate.Beneath,
      id: 2,
      order: 0,
      zIndex: 0,
    },
  ],
}

const backsideIds = range(1, 15)

export type PseudoTemplate = {
  id: string
  name: string
  template: DesignTemplate
  backsideId: number
  backsideUrl: string
  templateWidth: number
  templateHeight: number
  cropStencilClassName?: string
}

export const pseudoTemplates: Record<number, PseudoTemplate[]> = {
  72963: backsideIds.map((backsideId) => ({
    id: `${72963}-${backsideId}`,
    name: getName(72963, backsideId),
    template: tree,
    backsideId,
    backsideUrl: getBacksideUrl(72963, backsideId),
    templateWidth: 2670,
    templateHeight: 1844,
    cropStencilClassName: 'stencil-tree',
  })),
  72965: backsideIds.map((backsideId) => ({
    id: `${72965}-${backsideId}`,
    name: getName(72965, backsideId),
    template: star,
    backsideId,
    backsideUrl: getBacksideUrl(72965, backsideId),
    templateWidth: 2670,
    templateHeight: 1519,
    cropStencilClassName: 'stencil-star',
  })),
  72960: backsideIds.map((backsideId) => ({
    id: `${72960}-${backsideId}`,
    name: getName(72960, backsideId),
    template: stocking,
    backsideId,
    backsideUrl: getBacksideUrl(72960, backsideId),
    templateWidth: 2670,
    templateHeight: 2026,
    cropStencilClassName: 'stencil-stocking',
  })),
}
