import { AxiosInstance } from 'axios'
import {
  AddItemToCart,
  UpdateItemCartQuantity,
  GetCart,
  DeleteCartItem,
  GetCartResponse,
  AddItemToCartResponse,
  UpdateItemCartQuantityResponse,
  GetCheckoutSession,
  CreateCheckoutSession,
  ResumeCart,
  Cart,
} from './types/photoProductsCart'

export class PhotoProductsCartApi {
  api: AxiosInstance

  constructor(api: AxiosInstance) {
    this.api = api
  }

  addItemToCart: AddItemToCart = async (payload) => {
    const { data } = await this.api.post<AddItemToCartResponse>(
      '/v1/photo-products-cart/items',
      payload
    )

    return data
  }

  updateItemCartQuantity: UpdateItemCartQuantity = async (itemId, payload) => {
    const { data } = await this.api.put<UpdateItemCartQuantityResponse>(
      `/v1/photo-products-cart/items/${itemId}`,
      payload
    )

    return data
  }

  getCart: GetCart = async () => {
    const { data } = await this.api.get<GetCartResponse>(
      '/v1/photo-products-cart'
    )
    return data
  }

  deleteCartItem: DeleteCartItem = async (itemId) => {
    const { data } = await this.api.delete<Cart>(
      `/v1/photo-products-cart/items/${itemId}`
    )
    return data
  }

  createCheckoutSession: CreateCheckoutSession = async () => {
    const { data } = await this.api.post<{
      clientSecret: string
      checkoutSessionId: string
    }>('/v1/photo-products-cart/checkout-session')
    return data
  }

  getCheckoutSession: GetCheckoutSession = async (id: string) => {
    const { data } = await this.api.get<{
      amount: number
      email: string
    }>(`/v1/photo-products-cart/checkout-session/${id}`)
    return data
  }

  resumeCart: ResumeCart = async () => {
    const { data } = await this.api.patch<Cart>(
      '/v1/photo-products-cart/resume'
    )

    return data
  }
}
