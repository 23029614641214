import { useCallback } from 'react'
import { PhotoSource } from '../../hooks/useRecordUserAction'
import Button from '../../shared/buttons/Button'
import PhotoSelector from './components/PhotoSelector'

export default function SelectPhotoPage({
  next,
  previous,
}: {
  next: (base64: string[], source: PhotoSource) => void
  previous: () => void
}) {
  const onPreviousClick = useCallback(() => {
    previous()
  }, [previous])

  return (
    <div className="h-screen bg-gray-2 relative flex flex-col pt-8">
      <header className="flex-0 px-4 space-y-2 mb-4 text-center">
        <h1 className="font-bold text-2xl">Select Photo</h1>
        <p>Select one photo from your device or from FamilyAlbum</p>
      </header>
      <main className="flex-1 px-4">
        <PhotoSelector numberOfPhotos={1} onPhotoSelected={next} />
      </main>
      <footer className="text-center space-y-4 p-2 bg-white sticky bottom-0">
        <div className="grid grid-cols-2 gap-2">
          <Button onClick={onPreviousClick} colorVariant="primary:invert">
            Back
          </Button>
          <Button disabled onClick={() => {}} colorVariant="primary">
            Preview
          </Button>
        </div>
      </footer>
    </div>
  )
}
