import { useParams, Navigate, useNavigate } from 'react-router-dom'
import { ListProduct, PhotoProductCategoryId, ProductCatalog } from '../../clients/productCatalog'
import PhotoGiftProductCard from '../../shared/PhotoGiftProductCard'
import { useCallback } from 'react'
import StickyFooter from '../../shared/StickyFooter'
import Button from '../../shared/buttons/Button'
import { useApi } from '../../hooks/useApi'
import ScrollToTop from '../../shared/ScrollToTop'

function getThumbUrlForProduct(provider: string, productId: number, variantId?: number) {
  if (variantId) {
    return `/photo-products/products/${provider}/${productId}/thumb-${variantId}.png`
  }
  return `/photo-products/products/${provider}/${productId}/thumb.png`
}

export default function SubCategoryPage({
  cartHeader,
  productCatalog,
}: {
  cartHeader: (title: string) => React.ReactNode
  productCatalog: ProductCatalog
}) {
  const { categoryId } = useParams()
  const navigate = useNavigate()
  const { recordDidSelectPodProduct } = useApi().recordUserAction.photoProducts

  const onBackClick = useCallback(() => {
    navigate('/photo-products/categories')
  }, [navigate])

  const onProductClick = useCallback(
    (product: ListProduct) => {
      recordDidSelectPodProduct({
        providerProductId: product.productId,
        productName: product.name,
        productVariantId: product.variantId,
        productPrintProvider: product.provider,
      })
      if (product.variantId) {
        navigate(`/photo-products/add/${product.productId}?variantId=${product.variantId}`, {
          state: {
            source: `category/${categoryId}`,
          },
        })
      } else {
        navigate(`/photo-products/add/${product.productId}`, {
          state: {
            source: `category/${categoryId}`,
          },
        })
      }
    },
    [categoryId, navigate]
  )

  if (!categoryId) {
    return <Navigate to="/photo-products" />
  }

  const categoryDefinition = productCatalog[categoryId as PhotoProductCategoryId]

  if (!categoryDefinition) {
    return <Navigate to="/photo-products" />
  }

  return (
    <>
      <ScrollToTop />
      <div className="flex flex-col h-screen">
        {cartHeader('Select a Photo Gift')}
        <div className="px-4 grow pb-8">
          <ul className="grid grid-cols-2 gap-4">
            {categoryDefinition.products.map((product) => (
              <li key={`${product.productId}-${product.variantId}`}>
                <button className="w-full" onClick={() => onProductClick(product)}>
                  <PhotoGiftProductCard
                    src={getThumbUrlForProduct('PRINTIFY', product.productId, product.variantId)}
                    text={product.name}
                    rebate={product.rebate}
                  />
                </button>
              </li>
            ))}
          </ul>
        </div>
        <StickyFooter>
          <Button colorVariant="primary" className="w-full" onClick={onBackClick}>
            Back
          </Button>
        </StickyFooter>
      </div>
    </>
  )
}
