import { useMemo } from 'react'
import {
  Variant,
  VariantSizeOption,
  VariantColorOption,
  VariantShapeOption,
} from '../../../clients/fagl-server/types/photoProducts'
import VariantSelector from './VariantSelector'

function reduceVariantsToSizeOptions(variants: Variant[]) {
  return variants.reduce((acc, variant) => {
    const sizeOption = variant.options.find((option) => option.type === 'SIZE')
    if (sizeOption && !acc.find((o) => o.option.value === sizeOption.value)) {
      return [...acc, { variantId: variant.variantId, option: sizeOption as VariantSizeOption }]
    }
    return acc
  }, [] as { variantId: number; option: VariantSizeOption }[])
}

function reduceVariantsToShapeOptions(variants: Variant[]) {
  return variants.reduce((acc, variant) => {
    const shapeOption = variant.options.find((option) => option.type === 'SHAPE')
    if (shapeOption && !acc.find((o) => o.option.value === shapeOption.value)) {
      return [...acc, { variantId: variant.variantId, option: shapeOption as VariantShapeOption }]
    }
    return acc
  }, [] as { variantId: number; option: VariantShapeOption }[])
}

function reduceVariantsToColorOptions(variants: Variant[]) {
  return variants.reduce((acc, variant) => {
    const colorOption = variant.options.find((option) => option.type === 'COLOR')
    if (colorOption && !acc.find((o) => o.option.value === colorOption.value)) {
      return [...acc, { variantId: variant.variantId, option: colorOption as VariantColorOption }]
    }
    return acc
  }, [] as { variantId: number; option: VariantColorOption }[])
}

export default function VariantSelectionSection({
  variants,
  selectedVariantId,
  updateVariantId,
}: {
  variants: Variant[]
  selectedVariantId: number
  updateVariantId: (variantId: number) => void
}) {
  const colorOptions = useMemo(() => {
    return reduceVariantsToColorOptions(variants)
  }, [variants])

  const sizeOptions = useMemo(() => {
    return reduceVariantsToSizeOptions(variants)
  }, [variants])

  const shapeOptions = useMemo(() => {
    return reduceVariantsToShapeOptions(variants)
  }, [variants])

  const hasVariantsWithColors = colorOptions.filter(Boolean).length > 1
  const hasVariantsWithSizes = sizeOptions.filter(Boolean).length > 1
  const hasVariantsWithShapes = shapeOptions.filter(Boolean).length > 1
  const selectedVariant = variants.find((variant) => variant.variantId === selectedVariantId)

  const colorName = selectedVariant?.options.find((option) => option.type === 'COLOR')?.value

  return (
    <>
      {hasVariantsWithColors && (
        <VariantSelector
          title="Choose Color"
          option="color"
          selectedVariantId={selectedVariantId}
          updateVariantId={updateVariantId}
          additionalInfo={colorName}
          colorOptions={colorOptions}
        />
      )}
      {hasVariantsWithSizes && (
        <VariantSelector
          title="Choose Size"
          option="size"
          selectedVariantId={selectedVariantId}
          updateVariantId={updateVariantId}
          sizeOptions={sizeOptions}
        />
      )}
      {hasVariantsWithShapes && (
        <VariantSelector
          title="Choose Shape"
          option="shape"
          selectedVariantId={selectedVariantId}
          updateVariantId={updateVariantId}
          shapeOptions={shapeOptions}
        />
      )}
    </>
  )
}
