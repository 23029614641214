import React, { createContext, useContext, useState, useCallback } from 'react'
import { CuratedPhoto, CuratedPhotoForPhotoProducts } from '../../../clients/fagl-server/types'

interface CuratedPhotosContextType {
  curatedPhotos: CuratedPhoto[]
  curatedPhotosForPhotoProducts: CuratedPhotoForPhotoProducts[]
  arePhotosForPhotoProductsLoaded: boolean
  arePhotosLoaded: boolean
  isUsingFallback: boolean
  isUsingFallbackForPhotoProducts: boolean
  isShowingUserPhotos: boolean
  setCuratedPhotos: (photos: CuratedPhoto[]) => void
  setCuratedPhotosForPhotoProducts: (photos: CuratedPhotoForPhotoProducts[]) => void
  setArePhotosForPhotoProductsLoaded: (loaded: boolean) => void
  setArePhotosLoaded: (loaded: boolean) => void
  setIsUsingFallback: (fallback: boolean) => void
  setIsUsingFallbackForPhotoProducts: (fallback: boolean) => void
  setIsShowingUserPhotos: (showing: boolean) => void
}

const CuratedPhotosContext = createContext<CuratedPhotosContextType | null>(null)

export function CuratedPhotosProvider({ children }: { children: React.ReactNode }) {
  const [curatedPhotos, setCuratedPhotos] = useState<CuratedPhoto[]>([])
  const [curatedPhotosForPhotoProducts, setCuratedPhotosForPhotoProducts] = useState<
    CuratedPhotoForPhotoProducts[]
  >([])
  const [arePhotosForPhotoProductsLoaded, setArePhotosForPhotoProductsLoaded] = useState(false)
  const [arePhotosLoaded, setArePhotosLoaded] = useState(false)
  const [isShowingUserPhotos, setIsShowingUserPhotos] = useState(false)
  const [isUsingFallback, setIsUsingFallback] = useState(false)
  const [isUsingFallbackForPhotoProducts, setIsUsingFallbackForPhotoProducts] = useState(false)
  const value = {
    curatedPhotos,
    curatedPhotosForPhotoProducts,
    arePhotosForPhotoProductsLoaded,
    arePhotosLoaded,
    isUsingFallback,
    isUsingFallbackForPhotoProducts,
    isShowingUserPhotos,
    setCuratedPhotos,
    setCuratedPhotosForPhotoProducts,
    setArePhotosForPhotoProductsLoaded,
    setArePhotosLoaded,
    setIsUsingFallback,
    setIsUsingFallbackForPhotoProducts,
    setIsShowingUserPhotos,
  };

  return <CuratedPhotosContext.Provider value={value}>{children}</CuratedPhotosContext.Provider>
}

export const useCuratedPhotosContext = () => {
  const context = useContext(CuratedPhotosContext);
  if (!context) {
    throw new Error('useCuratedPhotosContext must be used within a CuratedPhotosProvider')
  }
  return context
} 