export default function BannerSection() {
  return (
    <section className="flex flex-col items-center justify-center space-y-2 pt-6 pb-8">
      <div className="ribbon text-white text-15 font-bold py-2">
        <span className="mx-4">Up to 30% OFF Photo Gifts</span>
      </div>
      <p className="text-gray-12 font-semibold text-center">
        Make family members smile with unforgettable personalized gifts
      </p>
    </section>
  )
}
