import cx from 'classnames'
import Footer from './Footer'

export default function StickyFooter({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return <Footer className={cx('sticky bottom-0 w-full', className)}>{children}</Footer>
}
