import { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import {
  Cell,
  PhotoProductEditCompleteCallback,
  PhotoProductsSourceImage,
} from '../../clients/fagl-server/types'
import Template from './select-template/Template'
import Button from '../../shared/buttons/Button'
import { BottomPanel } from '../../shared/BottomPanel'
import PhotoSelector from './components/PhotoSelector'
import useCrop from './hooks/useCrop'
import CropDialog from './components/CropDialog'
import DialogCloseButton from '../../shared/DialogCloseButton'
import { PseudoTemplate } from '../../clients/pseudoTemplates'
import CropIcon from '../../assets/crop.svg?react'
import { useApi } from '../../hooks/useApi'
import { PhotoSource } from '../../hooks/useRecordUserAction'

type CellEditState = {
  cellDefinition: Cell
  photoUrl: string | null
  replacementPhotoUrl: string | null
  cropPreviewUrl?: string
}

function CellOverlay(props: {
  cell: Cell
  photoUrl: string | null
  style: React.CSSProperties
  index: number
  cellZIndex: number
}) {
  const { photoUrl, style, index, cellZIndex } = props

  return photoUrl ? (
    <div
      className={cx('w-full h-full peer')}
      style={{
        ...style,
        zIndex: cellZIndex + 2,
      }}
    />
  ) : (
    <div style={style} className="w-full h-full peer bg-[#E5E5E5] flex items-center justify-center">
      <div className="bg-white w-12 h-12 rounded-full flex items-center justify-center text-gray-9 font-bold">
        {index}
      </div>
    </div>
  )
}

export default function BackFrontPseudoTemplateEdit({
  frontSideSourceImages,
  previous,
  next,
  templateWidth,
  pseudoTemplate,
  templateHeight,
  isPreparingEdit,
  isEditMode,
}: {
  frontSideSourceImages: PhotoProductsSourceImage
  pseudoTemplate: PseudoTemplate
  previous: () => void
  next: PhotoProductEditCompleteCallback
  templateWidth: number
  templateHeight: number
  isPreparingEdit: boolean
  isEditMode: boolean
}) {
  if (!pseudoTemplate) {
    throw new Error('ERROR_CAUSED_BY_REFRESH')
  }

  const { recordDidSelectPhotos } = useApi().recordUserAction.photoProducts
  const { cells } = pseudoTemplate.template
  const [isPristine, setIsPristine] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)

  const [frontCellState, setFrontCellState] = useState<CellEditState>({
    cellDefinition: cells[0],
    photoUrl: frontSideSourceImages.original,
    replacementPhotoUrl: frontSideSourceImages.cropped,
  })

  const [isConfirmingCrop, setIsConfirmingCrop] = useState(false)
  const [isChangingPhoto, setIsChangingPhoto] = useState(false)

  const { initCrop, isCropping, setIsCropping, confirmCrop, urls } = useCrop({
    original: frontSideSourceImages.original,
    cropped: frontSideSourceImages.cropped,
  })

  const openCropDialog = useCallback(() => {
    setIsCropping(true)
  }, [setIsChangingPhoto])

  const onConfirmCrop = useCallback(
    (cropped: string, previewDataUrl?: string) => {
      recordDidSelectPhotos({})
      setIsConfirmingCrop(true)
      confirmCrop(cropped)
      setFrontCellState((prev) => {
        return {
          ...prev,
          photoUrl: urls.original,
          replacementPhotoUrl: cropped,
          cropPreviewUrl: previewDataUrl,
        }
      })

      setIsPristine(false)
      setIsCropping(false)
      setIsChangingPhoto(false)
      setIsConfirmingCrop(false)
    },
    [
      confirmCrop,
      recordDidSelectPhotos,
      setFrontCellState,
      setIsCropping,
      setIsChangingPhoto,
      urls,
      setIsPristine,
    ]
  )

  const onNewPhotosSelected = useCallback(
    (newPhotoDataurl: string[], source: PhotoSource) => {
      initCrop(newPhotoDataurl[0])
      setIsCropping(true)
      recordDidSelectPhotos({
        photosSelected: 1,
        photosRequired: 1,
        photoSource: source,
      })
      setIsPristine(false)
    },
    [initCrop, setIsCropping, recordDidSelectPhotos, setIsPristine]
  )

  const onPrevious = useCallback(() => {
    previous()
  }, [previous])

  const sourceImageDataUrls = frontCellState
    ? [
        {
          original: frontCellState.photoUrl ?? '',
          cropped: frontCellState.cropPreviewUrl ?? '',
        },
      ]
    : []

  const onNext = useCallback(async () => {
    const photoUrl = frontCellState?.replacementPhotoUrl ?? ''
    next({
      isPristine,
      photoUrl,
      printAreas: [
        { position: 'front', imageUrl: photoUrl },
        { position: 'back', imageUrl: pseudoTemplate.backsideUrl },
      ],
      sourceImageDataUrls: [
        {
          original: frontCellState?.photoUrl ?? '',
          cropped: frontCellState?.replacementPhotoUrl ?? '',
        },
        {
          original: pseudoTemplate.backsideUrl,
          cropped: pseudoTemplate.backsideUrl,
        },
      ],
    })
  }, [next, setIsProcessing, sourceImageDataUrls, frontCellState, isEditMode, isPristine])

  useEffect(() => {
    setFrontCellState({
      cellDefinition: cells[0],
      photoUrl: frontSideSourceImages.original,
      replacementPhotoUrl: frontSideSourceImages.cropped,
      cropPreviewUrl: frontSideSourceImages.cropped,
    })
  }, [frontSideSourceImages])

  const title = 'Select 1 photo'

  // For tree stencil we use the cropped photo, for other stencils we try the replacement photo,
  // and if that's not available we use the original photo
  const photoToUseForFrontCell =
    frontCellState?.cropPreviewUrl ||
    frontCellState?.replacementPhotoUrl ||
    frontCellState?.photoUrl

  const photos = [photoToUseForFrontCell, pseudoTemplate.backsideUrl]

  return (
    <div className="h-screen bg-gray-2 relative">
      <div className="p-4 space-y-4">
        <h2 className="text-lg font-bold py-4">{title}</h2>
        <div className="space-y-2">
          <div id="template">
            <Template
              templateWidth={templateWidth}
              templateHeight={templateHeight}
              designTemplate={pseudoTemplate.template}
              photos={photos}
              renderOnCell={CellOverlay}
            />
          </div>
        </div>
        {photoToUseForFrontCell && (
          <Button
            isLoading={isPreparingEdit}
            colorVariant="primary:invert:no-border:transparent"
            disabled={isEditMode && isPreparingEdit}
            onClick={openCropDialog}
            className="flex items-center font-bold text-s justify-center w-full"
          >
            <CropIcon className="w-6 mr-2" />
            Crop
          </Button>
        )}
        <PhotoSelector
          verticalLayout
          familyAlbumButtonVariant="primary"
          onPhotoSelected={onNewPhotosSelected}
        />
        <CropDialog
          stencilClassName={pseudoTemplate.cropStencilClassName}
          isConfirming={isConfirmingCrop}
          url={urls.original}
          aspect={
            frontCellState?.cellDefinition.width && frontCellState?.cellDefinition.height
              ? frontCellState.cellDefinition.width / frontCellState.cellDefinition.height
              : 1
          }
          isOpen={isCropping}
          confirm={onConfirmCrop}
          close={() => {
            setIsCropping(false)
          }}
        />
        <BottomPanel
          isOverlayOpen={isChangingPhoto}
          close={() => {
            setIsChangingPhoto(false)
          }}
        >
          <div className="bg-white px-4 pb-8 pt-4 rounded-t-lg w-screen">
            <div>
              <DialogCloseButton onClick={() => setIsChangingPhoto(false)} />
            </div>
            <h3 className="font-bold text-center mb-4">Select a photo</h3>
            <div className="grid grid-col-1 gap-2">
              <PhotoSelector
                verticalLayout
                familyAlbumButtonVariant="primary"
                onPhotoSelected={onNewPhotosSelected}
              />
            </div>
          </div>
        </BottomPanel>
      </div>
      <footer className="text-center space-y-4 p-2 bg-white sticky w-full top-full">
        <div className="grid grid-cols-2 gap-2">
          <Button disabled={isProcessing} onClick={onPrevious} colorVariant="primary:invert">
            Back
          </Button>
          <Button
            isLoading={isProcessing}
            disabled={isProcessing || !photoToUseForFrontCell}
            onClick={onNext}
            colorVariant="primary"
          >
            Preview
          </Button>
        </div>
      </footer>
    </div>
  )
}
