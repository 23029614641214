import { useCallback, useState } from 'react'
import PersonalizePage from './Personalize'
import useCrop from './hooks/useCrop'
import {
  PhotoProductEditCompleteCallback,
  Variant,
} from '../../clients/fagl-server/types/photoProducts'
import SelectPhotoPage from './SelectPhoto'
import { useApi } from '../../hooks/useApi'
import { PhotoSource } from '../../hooks/useRecordUserAction'

export default function SinglePhotoEdit({
  cancelCartItemEdit,
  variants,
  next,
  previous,
  variant,
  sourceImages,
  isPreparingEdit,
  isEditMode,
}: {
  cancelCartItemEdit: () => void
  variants: Variant[]
  currency: string
  next: PhotoProductEditCompleteCallback
  previous: () => void
  isPreparingEdit: boolean
  variant: Variant
  sourceImages: {
    original: string
    cropped: string
  }[]
  isEditMode: boolean
}) {
  const [isPristine, setIsPristine] = useState(true)
  const [isConfirmingCrop, setIsConfirmingCrop] = useState(false)
  const { recordDidSelectPhotos } = useApi().recordUserAction.photoProducts
  const { isCropping, initCrop, resetCrop, fullReset, confirmCrop, closeCropDialog, urls } =
    useCrop(
      sourceImages[0]
        ? {
            original: sourceImages[0]?.original ?? null,
            cropped: sourceImages[0]?.cropped ?? null,
          }
        : null
    )

  const onCloseCropDialog = useCallback(() => {
    if (!urls.cropped) {
      fullReset()
    }
    closeCropDialog()
  }, [closeCropDialog, fullReset, urls])

  const onPhotoSelected = useCallback(
    (base64: string[], source: PhotoSource) => {
      initCrop(base64[0])
      recordDidSelectPhotos({
        photosSelected: 1,
        photosRequired: 1,
        photoSource: source,
      })
      setIsPristine(false)
    },
    [recordDidSelectPhotos, setIsPristine]
  )

  const onCropConfirmed = useCallback(
    (base64: string) => {
      setIsConfirmingCrop(true)
      confirmCrop(base64)
      setIsConfirmingCrop(false)
      setIsPristine(false)
    },
    [confirmCrop, setIsConfirmingCrop, setIsPristine]
  )

  const onNext = useCallback(
    async (variantId: number) => {
      if (!urls.cropped) {
        return
      }

      return next({
        photoUrl: urls.cropped,
        sourceImageDataUrls: [
          {
            original: urls.original ?? '',
            cropped: urls.cropped ?? '',
          },
        ],
        isPristine,
        variantId,
      })
    },
    [isEditMode, isPristine, next, urls.cropped, urls.original]
  )

  const onNewPhotoSelected = useCallback(
    (dataUrls: string[]) => {
      resetCrop(dataUrls[0])
    },
    [resetCrop]
  )

  if (!urls.original) {
    return <SelectPhotoPage next={onPhotoSelected} previous={previous} />
  }

  return (
    <PersonalizePage
      isConfirmingCrop={isConfirmingCrop}
      key={variant.variantId}
      isPreparingEdit={isPreparingEdit}
      isEditMode={isEditMode}
      cancelCartItemEdit={cancelCartItemEdit}
      onNewPhotoSelected={onNewPhotoSelected}
      urls={urls}
      isCropping={isCropping}
      aspectRatio={variant.aspectRatio}
      confirmCrop={onCropConfirmed}
      openCropDialog={() => {
        if (urls.original) {
          initCrop(urls.original)
        }
      }}
      closeCropDialog={onCloseCropDialog}
      next={onNext}
      initialVariantId={variant.variantId}
      variants={variants}
      previous={fullReset}
    />
  )
}
