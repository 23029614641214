import { useCallback, useEffect } from 'react'
import { Variant } from './types'
import { PhotoProductCategoryId, ProductCatalog } from '../../../clients/productCatalog'
import Button from '../../../shared/buttons/Button'
import FooterDisclaimer from '../../../shared/FooterDisclaimer'
import StickyFooter from '../../../shared/StickyFooter'
import BannerSection from './BannerSection'
import CategoryProductsCarousel from './CategoryProductsCarousel'
import CategoryTiles from './CategoryTiles'
import LargeBannerSection from './LargeBannerSection'
import FaqSection from './FaqSection'
import ShippingInfo from './ShippingInfo'
import { useNavigate } from 'react-router-dom'
import { useApi } from '../../../hooks/useApi'
import CuratedPhotosHeader from './CuratedPhotosHeader'
import { useCuratedPhotosContext } from '../contexts/CuratedPhotosContext'

const getStaticFileFolder = (variant: Variant) => {
  if (variant === 'HOLIDAY') {
    return '/photo-products/landing/holiday'
  }
  return '/photo-products/landing/default'
}

export default function PhotoProductsLanding({
  variant = 'DEFAULT',
  supportEmail,
  productCatalog,
}: {
  variant?: Variant
  supportEmail: string
  productCatalog: ProductCatalog
}) {
  const {
    recordUserAction: {
      photoProducts: { recordDidTapPodC2A, recordDidViewPodLp },
    },
  } = useApi()
  const {
    curatedPhotosForPhotoProducts,
    arePhotosForPhotoProductsLoaded,
    isUsingFallbackForPhotoProducts,
  } = useCuratedPhotosContext()

  const navigate = useNavigate()
  const staticFileFolder = getStaticFileFolder(variant)

  const onC2aClick = useCallback(
    (clickedElement: string) => {
      navigate('/photo-products/categories')
      recordDidTapPodC2A({ clickedElement })
    },
    [navigate, recordDidTapPodC2A]
  )

  const onCategoryClick = useCallback(
    (category: PhotoProductCategoryId, clickedElement: string) => {
      const numberOfProductsInSelectedCategory = productCatalog[category].products.length
      const skipPodSubCategory = numberOfProductsInSelectedCategory === 1
      recordDidTapPodC2A({ clickedElement, skippedPodSubCategory: skipPodSubCategory })
      if (skipPodSubCategory) {
        navigate(`/photo-products/add/${productCatalog[category].products[0].productId}`, {
          state: { source: '' },
        })
      } else {
        navigate(`/photo-products/category/${category}`)
      }
    },
    [navigate, recordDidTapPodC2A]
  )

  useEffect(() => {
    if (arePhotosForPhotoProductsLoaded) {
      recordDidViewPodLp({
        pageVariant: variant,
        isShowingUserPhotos: !isUsingFallbackForPhotoProducts
      })
    }
  }, [arePhotosForPhotoProductsLoaded, isUsingFallbackForPhotoProducts])

  return (
    <>
      <CuratedPhotosHeader
        staticFileFolder={staticFileFolder}
        curatedPhotos={curatedPhotosForPhotoProducts}
        arePhotosForPhotoProductsLoaded={arePhotosForPhotoProductsLoaded}
        isUsingFallback={isUsingFallbackForPhotoProducts}
      />
      <BannerSection />
      <main className="space-y-4">
        <CategoryTiles
          categories={['MUGS', 'SOCKS', 'PUZZLES', 'ORNAMENTS']}
          staticFileFolder={staticFileFolder}
          selectCategory={onCategoryClick}
        />
        <CategoryProductsCarousel
          title="Festive Mugs"
          category="MUGS"
          staticFileFolder={staticFileFolder}
          numberOfPhotos={6}
          selectCategory={onCategoryClick}
        />
        <CategoryProductsCarousel
          title="Ornaments"
          category="ORNAMENTS"
          staticFileFolder={staticFileFolder}
          numberOfPhotos={6}
          selectCategory={onCategoryClick}
        />
        <CategoryProductsCarousel
          title="Holiday Socks"
          category="SOCKS"
          staticFileFolder={staticFileFolder}
          numberOfPhotos={6}
          selectCategory={onCategoryClick}
        />
        <CategoryProductsCarousel
          title="Puzzles"
          category="PUZZLES"
          staticFileFolder={staticFileFolder}
          numberOfPhotos={6}
          selectCategory={onCategoryClick}
        />
      </main>
      <LargeBannerSection
        staticFileFolder={staticFileFolder}
        title="Up to 30% OFF"
        description="All Custom Photo Gifts"
        callToAction="Shop Now"
        onCallToActionClick={() => onC2aClick('large-banner')}
      />
      <ShippingInfo />
      <FaqSection supportEmail={supportEmail} />
      <footer className="px-4 pb-8 mt-12">
        <p>
          Have questions? Contact us at <br />
          <a className="text-fa-primary" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
        </p>
        <FooterDisclaimer />
      </footer>
      <StickyFooter>
        <Button
          onClick={() => onC2aClick('footer')}
          className="p-2 block w-full"
          colorVariant="primary"
        >
          Create My Gift & Save 30%
        </Button>
      </StickyFooter>
    </>
  )
}
