export default function ShippingInfo() {
  return (
    <section className="px-4 bg-white text-gray-12 mb-12 pt-8">
      <h2 className="font-bold text-2xl text-center mb-4">Shipping</h2>
      <div className="mb-4">
        <h3 className="font-bold mb-2">Cost:</h3>
        <p className="mb-2">
          Shipping charges and times vary by product type, location and method. Enter your shipping
          address at checkout for details.
        </p>
        <p className="text-fa-text-accent-secondary">
          Note: Photo Gifts are not eligible for free shipping for Premium users.
        </p>
      </div>
      <div className="mb-4">
        <h3 className="font-bold mb-2">Delivery:</h3>
        <p className="mb-2">
          Photo Gifts ship only within the U.S. Delivery to the mainland U.S. takes about 4 to 8
          business days.* Tracking information will be sent via email once your order ships.
        </p>
        <p className="text-gray-11 text-[13px]">
          *For Alaska, Hawaii, Puerto Rico, and U.S. territories, please allow an additional 7 to 12
          business days.
        </p>
      </div>
    </section>
  )
}
