import { useEffect, useState } from 'react'
import ApiClient from '../../ApiClient'
import { useRollbar } from '@rollbar/react'
import { pHash, comparePHashes } from './ImageHash/phash'

const HASH_DIFFERENCE_THRESHOLD = 1

export default function useImageHashCompare(api: ApiClient, variantId?: number) {
  const rollbar = useRollbar()
  const [baseImageHashes, setBaseImageHashes] = useState<string[]>([])

  useEffect(() => {
    if (!variantId) return

    const getBaseImageHashes = async (variantId: number) => {
      try {
        const hashes = await api.photoProducts.getHashesForBaseImages(variantId)
        setBaseImageHashes(hashes)
      } catch (error) {
        if (error instanceof Error) {
          rollbar.error(`Failed to get hashes for base images for variantId: ${variantId}`, error)
        } else {
          rollbar.error('Failed to get hashes for base images', new Error('Unknown error'))
        }
      }
    }
    getBaseImageHashes(variantId)
  }, [variantId, api, rollbar])

  const loadImage = (base64: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = base64
      img.onload = () => resolve(img)
      img.onerror = (error) => reject(error)
    })
  }

  const hashImage = async (base64: string): Promise<string> => {
    try {
      const img = await loadImage(base64)
      const hash = await pHash(img)
      return hash
    } catch (error) {
      throw error
    }
  }

  const areHashesDifferent = (hashA: string, hashB: string): boolean => {
    const distance = comparePHashes(hashA, hashB)
    return distance > HASH_DIFFERENCE_THRESHOLD
  }

  const isImageHashDifferentFromBaseImageHashes = (
    hashA: string,
    baseImageHashes: string[]
  ): boolean => {
    return baseImageHashes.some((hashB) => !areHashesDifferent(hashA, hashB))
  }

  return {
    baseImageHashes,
    hashImage,
    isImageDifferentFromBaseImages: isImageHashDifferentFromBaseImageHashes,
  }
}
