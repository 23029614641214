import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import TermsOfUsePanel from './components/TermsOfUsePanel'
import { useNavigate } from 'react-router-dom'
import { AddItemToCartPayload, Cart } from '../../clients/fagl-server/types/photoProductsCart'
import CartPage from './Checkout/Cart'
import { create2DecimalsCurrencyFormatter } from '../../utils'
import StripeCheckoutPage from './Checkout/StripeCheckout'
import LoadingOverlay from './components/LoadingOverlay'
import ProductWrapper from './components/ProductWrapper'
import CreateFlow from './CreateFlow'
import PhotoProductsLanding from './landing'
import SubCategoryPage from './SubCategoryPage'
import ShoppingCartBadge from './components/ShoppingCartBadge'
import { useApi } from '../../hooks/useApi'
import { HeadSegmentationContext } from './hooks/useHeadSegmentation'
import { HeadSegmentation } from '../../clients/HeadSegmentation'
import CategoryPage from './CategoryPage'
import { getProductCatalogForMarket } from '../../clients/productCatalog'
import CuratedPhotosWrapper from './CuratedPhotosWrapper'

const MAX_QUANTITY = 10
const hsClient = new HeadSegmentation()
export default function PhotoProductsApp({
  isPrintifyPreviewLogsActive,
  supportEmail,
}: {
  isPrintifyPreviewLogsActive: boolean
  supportEmail: string
}) {
  const location = useLocation()
  const navigate = useNavigate()

  const {
    api,
    session: { market },
    recordUserAction: {
      photoProducts: {
        recordDidAddProductToCart,
        recordDidTapShoppingCart,
        recordDidTapToGetToCheckout,
        recordDidResumeCart,
        recordDidCompleteCheckout,
      },
    },
  } = useApi()

  const productCatalog = useMemo(() => getProductCatalogForMarket(market), [market])

  const [cartExistsOnFirstLoad, setCartExistsOnFirstLoad] = useState(false)
  const [isCartLoaded, setIsCartLoaded] = useState(false)
  const [isCartAlreadyResumedInSession, setIsCartAlreadyResumedInSession] = useState(false)
  const [cart, setCart] = useState<Cart | null>(null)
  const [editedCartItemId, setEditedCartItemId] = useState<number | null>(null)

  const cartItems = cart?.items || []
  const editedCartItem = useMemo(() => {
    return cartItems.find((item) => item.id === editedCartItemId)
  }, [cartItems, editedCartItemId])

  const [isTermsOfUsePageVisible, setIsTermsOfUsePageVisible] = useState(false)



  const priceFormatter = useMemo(() => create2DecimalsCurrencyFormatter('usd'), [])

  const getCart = useCallback(async () => {
    try {
      const response = await api.photoProductsCart.getCart()
      if (response) {
        setCart(response)
        setCartExistsOnFirstLoad(true)
      }
    } catch (error) {
      console.error(error)
    } finally {
      // In the off chance a cart could not load, we still want to show the page
      setIsCartLoaded(true)
    }
  }, [api, isCartAlreadyResumedInSession])

  const addItemToCart = useCallback(
    async (payload: AddItemToCartPayload) => {
      setIsCartAlreadyResumedInSession(true)
      recordDidAddProductToCart({
        numberOfProductsInCart: cartItems.length + 1,
        providerProductId: payload.metadata.providerProductId,
        productName: payload.metadata.title,
      })
      if (editedCartItem) {
        await api.photoProductsCart.deleteCartItem(editedCartItem.id)
        setEditedCartItemId(null)
      }
      try {
        const response = await api.photoProductsCart.addItemToCart(payload)
        setCart(response)

        navigate('/photo-products/cart')
      } catch (error) {
        toast.error('Failed to add item to cart')
        console.error(error)
      }
    },
    [api, editedCartItem, recordDidAddProductToCart, cartItems.length]
  )

  const resumeCart = useCallback(async () => {
    recordDidResumeCart({
      numberOfProductsInCart: cartItems.length,
      cartId: cart?.id,
    })
    const response = await api.photoProductsCart.resumeCart()
    setCart(response)
  }, [api, cartItems.length, cart?.id])

  const updateItemQuantity = useCallback(
    async (itemId: number, quantity: number) => {
      try {
        const updatedCart = await api.photoProductsCart.updateItemCartQuantity(itemId, {
          quantity,
        })
        setCart(updatedCart)
      } catch (error) {
        toast.error('Failed to update item quantity')
        console.error(error)
      }
    },
    [api, cart, getCart]
  )

  const deleteCartItem = useCallback(
    async (itemId: number) => {
      try {
        const updatedCart = await api.photoProductsCart.deleteCartItem(itemId)
        setCart(updatedCart)
      } catch (error) {
        toast.error('Failed to remove item from cart')
        console.error(error)
      }
    },
    [api, cart, getCart]
  )

  const editItem = useCallback((itemId: number, providerProductId: number) => {
    setEditedCartItemId(itemId)
    navigate(`/photo-products/add/${providerProductId}/edit`)
  }, [])

  const cancelCartItemEdit = useCallback(() => {
    setEditedCartItemId(null)
    navigate('/photo-products/cart')
  }, [])

  const navigateToCheckoutFromCart = useCallback(() => {
    recordDidTapToGetToCheckout({
      numberOfProductsInCart: cartItems.length,
    })
    navigate('/photo-products/checkout')
  }, [navigate, cartItems.length])

  const onShoppingCartClick = useCallback(() => {
    recordDidTapShoppingCart({
      numberOfProductsInCart: cartItems.length,
    })
  }, [cartItems.length])

  const resumeExistingCart = useCallback(() => {
    setIsCartAlreadyResumedInSession(true)
    resumeCart()
  }, [resumeCart])

  const resumeCartIfNeeded = useCallback(() => {
    if (!isCartAlreadyResumedInSession) {
      resumeExistingCart()
    }
  }, [isCartAlreadyResumedInSession, resumeExistingCart])

  const onCompleteCheckout = useCallback(() => {
    recordDidCompleteCheckout({
      cartId: cart?.id,
      numberOfProductsInCart: cartItems.length,
    })
  }, [cartItems.length, cart?.id])

  useEffect(() => {
    const pathsToAutoResumeCart = ['/photo-products/categories', '/photo-products/category/']
    const isPathForCartResume = pathsToAutoResumeCart.some((path) =>
      location.pathname.includes(path)
    )

    if (cartExistsOnFirstLoad && isPathForCartResume && cart) {
      resumeCartIfNeeded()
    }
  }, [location, resumeCartIfNeeded, cart, cartExistsOnFirstLoad])

  useEffect(() => {
    getCart()
  }, [])

  const cartHeader = (title: string) => (
    <header className="p-4 text-black">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">{title}</h2>
        <Link to="/photo-products/cart" onClick={onShoppingCartClick}>
          <ShoppingCartBadge numberOfItemsInCart={cartItems.length} />
        </Link>
      </div>
    </header>
  )

  return (
    <CuratedPhotosWrapper>
      <Routes>
        <Route
          index
          element={
            <PhotoProductsLanding
              variant="HOLIDAY"
              supportEmail={supportEmail}
              productCatalog={productCatalog}
            />
          }
        />
        <Route
          path="/categories"
          element={<CategoryPage productCatalog={productCatalog} cartHeader={cartHeader} />}
        />
        <Route
          path="/category/:categoryId"
          element={<SubCategoryPage productCatalog={productCatalog} cartHeader={cartHeader} />}
        />
        <Route
          path="/cart"
          element={
            <CartPage
              editItem={editItem}
              updateItemQuantity={updateItemQuantity}
              deleteCartItem={deleteCartItem}
              maxQuantity={MAX_QUANTITY}
              next={navigateToCheckoutFromCart}
              cart={cart}
              priceFormatter={priceFormatter}
              navigateBackToSource={() => navigate('/photo-products/categories')}
            />
          }
        />
        <Route
          path="/checkout"
          element={
            <StripeCheckoutPage
              onBackClick={() => navigate('/photo-products/cart')}
              createCheckoutSession={api.photoProductsCart.createCheckoutSession}
              onCompleteCheckout={onCompleteCheckout}
              getCheckoutSession={api.photoProductsCart.getCheckoutSession}
              priceFormatter={priceFormatter}
            />
          }
        />
        <Route
          path="/add/:photoProductId/*"
          element={
            <ProductWrapper>
              {({ photoProduct, variants, productImages, carouselImages, initialVariantId }) => (
                <HeadSegmentationContext.Provider value={hsClient}>
                  <CreateFlow
                    cartHeader={cartHeader}
                    currency="usd"
                    productImages={productImages}
                    carouselImages={carouselImages}
                    photoProduct={photoProduct}
                    variants={variants}
                    initialVariantId={initialVariantId}
                    cancelCartItemEdit={cancelCartItemEdit}
                    addItemToCart={addItemToCart}
                    editedCartItem={editedCartItem ?? null}
                    openTermsOfUsePage={() => setIsTermsOfUsePageVisible(true)}
                    isPrintifyPreviewLogsActive={isPrintifyPreviewLogsActive}
                  />
                </HeadSegmentationContext.Provider>
              )}
            </ProductWrapper>
          }
        />
      </Routes>
      <TermsOfUsePanel
        isOpen={isTermsOfUsePageVisible}
        close={() => setIsTermsOfUsePageVisible(false)}
      />
      {!isCartLoaded && <LoadingOverlay opaque animationType="spinner" />}
    </CuratedPhotosWrapper>
  )
}
