import cx from 'classnames'
import React, { CSSProperties } from 'react'
import { useSnapCarousel } from 'react-snap-carousel'

const styles = {
  item: {
    flexShrink: 0,
  },
  itemSnapPoint: {
    scrollSnapAlign: 'start',
  },
  controls: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nextPrevButton: {},
  nextPrevButtonDisabled: { opacity: 0.3 },
  pagination: {
    display: 'flex',
  },
  paginationButton: {
    margin: '10px',
  },
  paginationButtonActive: { opacity: 0.3 },
  pageIndicator: {
    display: 'flex',
    justifyContent: 'center',
  },
} satisfies Record<string, CSSProperties>

interface CarouselProps<T> {
  readonly items: T[]
  readonly renderItem: (props: CarouselRenderItemProps<T>) => React.ReactElement<CarouselItemProps>
  readonly showControls?: boolean
  readonly showPagination?: boolean
  readonly scrollContainerClassName?: string
}

interface CarouselRenderItemProps<T> {
  readonly item: T
  readonly isSnapPoint: boolean
  readonly index: number
}

export const Carousel = <T extends any>({
  items,
  renderItem,
  showControls = false,
  showPagination = false,
  scrollContainerClassName,
}: CarouselProps<T>) => {
  const {
    scrollRef,
    pages,
    activePageIndex,
    hasPrevPage,
    hasNextPage,
    prev,
    next,
    goTo,
    snapPointIndexes,
  } = useSnapCarousel()
  return (
    <div>
      <ul
        className={cx('relative flex overflow-auto snap-x', scrollContainerClassName)}
        ref={scrollRef}
      >
        {items.map((item, i) =>
          renderItem({
            item,
            isSnapPoint: snapPointIndexes.has(i),
            index: i,
          })
        )}
      </ul>
      {showControls && (
        <div style={styles.controls} aria-hidden>
          <button
            style={{
              ...styles.nextPrevButton,
              ...(!hasPrevPage ? styles.nextPrevButtonDisabled : {}),
            }}
            onClick={() => prev()}
            disabled={!hasPrevPage}
          >
            Prev
          </button>
          {pages.map((_, i) => (
            <button
              key={i}
              style={{
                ...styles.paginationButton,
                ...(activePageIndex === i ? styles.paginationButtonActive : {}),
              }}
              onClick={() => goTo(i)}
            >
              {i + 1}
            </button>
          ))}
          <button
            style={{
              ...styles.nextPrevButton,
              ...(!hasNextPage ? styles.nextPrevButtonDisabled : {}),
            }}
            onClick={() => next()}
            disabled={!hasNextPage}
          >
            Next
          </button>
        </div>
      )}
      {showPagination && (
        <div style={styles.pageIndicator}>
          {activePageIndex + 1} / {pages.length}
        </div>
      )}
    </div>
  )
}

interface CarouselItemProps {
  readonly isSnapPoint: boolean
  readonly children?: React.ReactNode
  readonly className?: string
}

export const CarouselItem = ({ isSnapPoint, children, className }: CarouselItemProps) => (
  <li
    className={className}
    style={{
      ...styles.item,
      ...(isSnapPoint ? styles.itemSnapPoint : {}),
    }}
  >
    {children}
  </li>
)
