import { CuratedPhotosProvider } from './contexts/CuratedPhotosContext'
import { useEffect } from 'react'
import useCuratedPhotos from './hooks/useCuratedPhotos'
import LoadingOverlay from './components/LoadingOverlay'

interface CuratedPhotosWrapperProps {
  showLoadingOverlay?: boolean
  children: React.ReactNode
  landscapeOnly?: boolean
}

export default function CuratedPhotosWrapper({ showLoadingOverlay = true, children, landscapeOnly = false }: CuratedPhotosWrapperProps) {
  return (
    <CuratedPhotosProvider>
      <CuratedPhotosInitializer showLoadingOverlay={showLoadingOverlay} landscapeOnly={landscapeOnly}> 
        {children}
      </CuratedPhotosInitializer>
    </CuratedPhotosProvider>
  )
}

function CuratedPhotosInitializer({ children, showLoadingOverlay, landscapeOnly }: { children: React.ReactNode, showLoadingOverlay: boolean, landscapeOnly: boolean }) {
  const { getCuratedPhotos, isLoading } = useCuratedPhotos({
    convertToBase64: true,
  })
  useEffect(() => {
    getCuratedPhotos(landscapeOnly)
  }, [getCuratedPhotos, landscapeOnly])

  if (isLoading && showLoadingOverlay) {
    return <LoadingOverlay opaque animationType="spinner" />
  }

  return <>{children}</>
} 