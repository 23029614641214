const DeliveryBanner: React.FC = () => {
  const backgroundColor = 'bg-[#FCFAF2]'
  const textColor = 'text-[#A18737]'
  const message = 'ⓘ Orders placed now may be delivered after 12/25'
  return (
    <div
      className={`h-8 ${backgroundColor} ${textColor} font-bold flex overflow-hidden py-1 relative overflow-x-hidden`}
    >
      <div className="whitespace-nowrap animate-marquee">
        <span>{message}</span>
      </div>
      <div className="whitespace-nowrap animate-marquee2 absolute top-1 left-2">
        <span>{message}</span>
      </div>
    </div>
  )
}

export default DeliveryBanner
