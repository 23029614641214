import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useRollbar } from '@rollbar/react'
import { PrintAreaPosition, Variant } from '../../../clients/fagl-server/types/photoProducts'
import { CartItem, Provider } from '../../../clients/fagl-server/types/photoProductsCart'
import { useApi } from '../../../hooks/useApi'
import { chunk } from 'lodash'
import { usePreview, PreviewImage } from './usePreview'

export default function usePrintify({
  providerProductId,
  defaultVariantId,
  editedCartItem,
}: {
  providerProductId: number
  defaultVariantId: number
  editedCartItem: CartItem | null
}) {
  const rollbar = useRollbar()
  const {
    api,
    recordUserAction: {
      photoProducts: { recordPreviewSuccess, recordPreviewFailure },
    },
  } = useApi()

  const [variantId, setVariantId] = useState<number>(
    editedCartItem?.metadata.variantId || defaultVariantId
  )

  const [showNotAllLoadedError, setShowNotAllLoadedError] = useState(false)
  const [isGettingPreview, setIsGettingPreview] = useState(false)
  const [isPreparingEdit, setIsPreparingEdit] = useState(false)
  const [isGettingPreviewComplete, setIsGettingPreviewComplete] = useState(false)

  const [sourceImagesDataUrls, setSourceImagesDataUrls] = useState<
    {
      cropped: string
      original: string
    }[]
  >([])

  const [sourceImages, setSourceImages] = useState<{ cropped: string; original: string }[]>(
    editedCartItem?.metadata.sourceImages ?? []
  )

  const [printAreas, setPrintAreas] = useState<{ position: PrintAreaPosition; imageUrl: string }[]>(
    editedCartItem?.metadata.printAreas ?? []
  )

  const [itemIdentifier, setItemIdentifier] = useState<string | null>(
    editedCartItem?.itemIdentifier ?? null
  )
  const [quantity, setQuantity] = useState(editedCartItem?.quantity ?? 1)
  const {
    previewUrlState,
    setPreviewUrlState,
    fetchPreviewImages,
    previewPhotosList,
    previewStatus,
  } = usePreview(variantId, editedCartItem?.metadata.previewCdnUrls ?? [])
  const [fetchLogs, setFetchLogs] = useState<{ [key: string]: string[] }>({})

  const resetState = useCallback(() => {
    setPreviewUrlState({})
    setSourceImages([])
    setSourceImagesDataUrls([])
    setPrintAreas([])
    setQuantity(1)
    setItemIdentifier(null)
    setFetchLogs({})
    setIsGettingPreviewComplete(false)
  }, [])

  const uploadSourceImages = useCallback(
    async (
      imageUrls: {
        original: string
        cropped: string
      }[]
    ) => {
      try {
        setSourceImagesDataUrls(imageUrls)
        const result = await Promise.all(
          imageUrls.map(async (image) => {
            const [cropped, original] = await Promise.all([
              api.uploadBase64Image({
                base64: image.cropped,
                name: 'cropped',
              }),
              api.uploadBase64Image({
                base64: image.original,
                name: 'original',
              }),
            ])
            return {
              cropped: cropped.url,
              original: original.url,
            }
          })
        )

        setSourceImages(result)

        return result
      } catch (err) {
        rollbar.error('Error uploading used images', err as Error)
        console.error(err)
        return []
      }
    },
    []
  )

  const generatePreview = useCallback(
    async ({
      variant,
      sourceImageDataUrls,
      printAreas,
    }: {
      variant: Variant
      printAreas: { position: PrintAreaPosition; imageUrl: string }[]
      sourceImageDataUrls: {
        original: string
        cropped: string
      }[]
    }) => {
      // Convert the source photo data URLs to a CDN URLs and save to state.
      // When adding a cart item, we need those URLs to be stored in the cart item metadata
      // so we can restore them when editing the item
      uploadSourceImages(sourceImageDataUrls)

      setVariantId(variant.variantId)

      setItemIdentifier(null)
      setIsGettingPreview(true)
      setIsGettingPreviewComplete(false)
      setPreviewUrlState({})

      let printAreasWithCdnUrls: { position: PrintAreaPosition; imageUrl: string }[] = []
      try {
        printAreasWithCdnUrls = await Promise.all(
          printAreas.map(async (area) => {
            const { url: cdnUrl } = await api.uploadBase64Image({
              base64: area.imageUrl,
              name: area.position,
            })
            return { ...area, imageUrl: cdnUrl }
          })
        )

        setPrintAreas(printAreasWithCdnUrls)
      } catch (err) {
        toast.error('Something went wrong. Please try again later.')
        rollbar.error('Error uploading base 64 image', err as Error)
        console.error(err)
      }

      try {
        const { images, productId } = await api.photoProducts.getImagesForPreview({
          providerProductId,
          variantId: variant.variantId,
          printAreas: printAreasWithCdnUrls,
          printProviderId: variant.printProviderId,
        })

        setItemIdentifier(productId)

        await getPreview(images.map((image) => ({ ...image, initialBase64: '' })))
      } catch (err) {
        toast.error('Something went wrong. Please try again later.')
        rollbar.error('Error creating Printify order for preview purposes', err as Error)
        console.error(err)
      } finally {
        setIsGettingPreview(false)
      }
    },
    [providerProductId, api, rollbar, quantity]
  )

  const regeneratePreview = useCallback(async () => {
    setShowNotAllLoadedError(false)

    const payloadForGetPreviewForNotReadyPhotos = previewPhotosList.map((preview) => ({
      src: preview.url,
      is_default: preview.isDefault,
      initialBase64: '',
    }))

    getPreview(payloadForGetPreviewForNotReadyPhotos)
  }, [previewPhotosList])

  async function convertCdnPhotosToBase64(
    sourceImage: {
      original: string
      cropped: string
    }[]
  ) {
    setIsPreparingEdit(true)
    const response = await api.convertCdnPhotosToBase64(
      sourceImage.flatMap((image) => [
        { url: image.original, uuid: new Date().getTime().toString() },
        { url: image.cropped, uuid: new Date().getTime().toString() },
      ]),
      true
    )

    const convertedImages = chunk(response, 2).map(([original, cropped]) => ({
      original: original.base64,
      cropped: cropped.base64,
    }))

    setSourceImagesDataUrls(convertedImages)
    setIsPreparingEdit(false)
  }

  useEffect(() => {
    const { status, logPayload } = previewStatus
    if (status !== 'PENDING' && !isGettingPreviewComplete) {
      if (status === 'SUCCESS') {
        recordPreviewSuccess({
          ...logPayload,
          productPrintProvider: 'PRINTIFY',
        })
      } else if (status === 'FAILURE') {
        setShowNotAllLoadedError(true)
        recordPreviewFailure({
          ...logPayload,
          productPrintProvider: 'PRINTIFY',
        })
        rollbar.warning('Printify previews not ready', logPayload)
      }
      setIsGettingPreviewComplete(true)
    }
  }, [previewStatus, isGettingPreviewComplete])

  const previewCdnUrl =
    previewPhotosList.find((photo) => photo.isDefault)?.url || previewPhotosList[0]?.url

  const createPayloadWithVariant = useCallback(
    (selectedVariant: Variant) => {
      return {
        itemIdentifier,
        provider: Provider.PRINTIFY,
        metadata: {
          providerProductId,
          previewCdnUrl,
          sourceImages,
          printAreas,
          printProviderId: selectedVariant.printProviderId,
          variantId: selectedVariant.variantId,
          price: selectedVariant.price,
          salePrice: selectedVariant.salePrice,
          title: selectedVariant.title,
          previewCdnUrls: Object.values(previewUrlState).map((preview) => preview.url),
        },
      }
    },
    [providerProductId, previewCdnUrl, sourceImages, itemIdentifier, printAreas, previewUrlState]
  )

  // Convert the source images to base64 so we can use them in the personalize screen
  useEffect(() => {
    if (editedCartItem) {
      convertCdnPhotosToBase64(editedCartItem.metadata.sourceImages)
    }
  }, [editedCartItem])

  const getPreview = useCallback(
    async (images: PreviewImage[]) => {
      setIsGettingPreview(true)
      setShowNotAllLoadedError(false)

      await fetchPreviewImages(images)

      setIsGettingPreview(false)
    },
    [setIsGettingPreview, setShowNotAllLoadedError, fetchPreviewImages]
  )

  const resetSourcePhotos = useCallback(() => {
    setSourceImages([])
    setSourceImagesDataUrls([])
  }, [])

  return {
    quantity,
    setQuantity,
    providerProductId,
    generatePreview,
    previewPhotosList,
    isGettingPreview,
    showNotAllLoadedError,
    regeneratePreview,
    fetchLogs,
    updateVariantId: setVariantId,
    createPayloadWithVariant,
    variantId,
    resetState,
    sourceImages,
    sourceImagesDataUrls,
    isPreparingEdit,
    canSkipPreview: itemIdentifier !== null,
    resetSourcePhotos,
  }
}
