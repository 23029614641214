import { useCallback } from 'react'
import { Carousel, CarouselItem } from '../../../shared/Carousel'
import { PhotoProductCategoryId } from '../../../clients/productCatalog'

export default function CategoryProductsCarousel({
  category,
  staticFileFolder,
  numberOfPhotos,
  title,
  selectCategory,
}: {
  category: PhotoProductCategoryId
  staticFileFolder: string
  numberOfPhotos: number
  title: string
  selectCategory: (category: PhotoProductCategoryId, clickedElement: string) => void
}) {
  const onCategoryClick = useCallback(
    (clickedElement: string) => {
      selectCategory(category, clickedElement)
    },
    [selectCategory, category]
  )

  const photoUrls = Array.from({ length: numberOfPhotos }, (_, index) => {
    return `${staticFileFolder}/categories/${category}/carousel/${index + 1}.png`
  })
  return (
    <section className="space-y-4 px-4">
      <h3 className="font-bold text-lg">{title}</h3>
      <Carousel
        scrollContainerClassName="gap-4"
        items={photoUrls}
        renderItem={({ item, isSnapPoint, index }) => (
          <CarouselItem key={item} isSnapPoint={isSnapPoint}>
            <button
              onClick={() => onCategoryClick(`carousel-${category}-tile-${index}`)}
              className="rounded-lg overflow-hidden bg-gray-2"
            >
              <img src={item} className="rounded-lg" width="132" height="135" alt="" />
            </button>
          </CarouselItem>
        )}
      />
      <div className="text-right">
        <button
          onClick={() => onCategoryClick(`carousel-${category}-see-more`)}
          className="font-bold text-mi-primary"
        >
          See more design options
        </button>
      </div>
    </section>
  )
}
