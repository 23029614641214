import Button from '../../../shared/buttons/Button'
import MiteneLogo from '../../../assets/fa-logo-color.svg?react'
import useFamilyAlbumUserAgent from '../../../hooks/useFamilyAlbumUserAgent'

export default function Success({
  email,
  supportEmailAddress,
  helpEmailSubject,
  chargedAmount,
}: {
  email: string | null
  chargedAmount: string
  supportEmailAddress: string
  helpEmailSubject: string
}) {
  const { appVersionSupportsCloseWebView } = useFamilyAlbumUserAgent()

  const onBackToStoreClick = () => {
    // NOTE: The click of an anchor element must be triggered in order for the
    //       iOS app's web view to handle the action
    const link = document.createElement('a')
    link.href = '#TRIGGER_CLOSE_WEB_VIEW'
    link.click()
  }

  return (
    <div className="sm:pt-20 px-4">
      <div className="mx-auto mb-4 h-12 w-12 bg-white flex p-3 rounded-full items-center justify-center">
        <MiteneLogo />
      </div>
      <h1 className="text-xl px-12 font-bold text-center mb-8">
        Your order has been successfully placed!
      </h1>
      <div className="space-y-4 mb-4">
        <p>
          An order confirmation email has been sent to{' '}
          <span className="text-mi-primary">{email}</span>.
        </p>

        <p>
          <span className="font-bold">Important:</span>
          <br />
          Your order details will appear on the "My Order" page in the Store
          tab within the FamilyAlbum app. Also, updates regarding your order will
          be sent via email.
        </p>
        <div className="mb-8 space-y-4 p-4 rounded-lg bg-white">
          <div>
            <h4 className="text-mi-text-dark-gray">Payment Amount</h4>
            <p className="pl-4 font-bold">{chargedAmount}</p>
          </div>
        </div>
        {!appVersionSupportsCloseWebView && (
          <p className="mb-4">
            To return to your album, tap the ‘←’ or ‘X’ button at the top left.
          </p>
        )}
      </div>

      {appVersionSupportsCloseWebView && (
        <Button
          className="py-2 px-4 w-full sm:w-72 rounded-lg mb-8 mx-auto block"
          colorVariant="primary"
          onClick={onBackToStoreClick}
        >
          Close
        </Button>
      )}

      <div className="mb-2 pb-8">
        <h3 className="font-bold">Need help?</h3>
        <p>
          If you need to cancel or modify your order, please reach out to our
          customer support team at{' '}
          <a
            className="text-mi-primary"
            href={`mailto:${supportEmailAddress}?subject=${encodeURIComponent(
              helpEmailSubject
            )}`}
          >
            {supportEmailAddress}
          </a>
          .
        </p>
      </div>
    </div>
  )
}
