import { useCallback } from 'react'
import { PhotoProductCategoryId } from '../../../clients/productCatalog'

export default function CategoryTiles({
  categories,
  staticFileFolder,
  selectCategory,
}: {
  categories: PhotoProductCategoryId[]
  staticFileFolder: string
  selectCategory: (category: PhotoProductCategoryId, clickedElement: string) => void
}) {
  const onCategoryClick = useCallback(
    (category: PhotoProductCategoryId) => {
      selectCategory(category, `category-${category}`)
    },
    [selectCategory]
  )
  return (
    <section className="grid grid-cols-2 gap-4 px-4">
      {categories.map((category) => (
        <div key={category}>
          <button onClick={() => onCategoryClick(category)}>
            <img
              src={`${staticFileFolder}/categories/${category}/category-tile.png`}
              alt={category}
            />
          </button>
        </div>
      ))}
    </section>
  )
}
