import { useNavigate } from 'react-router-dom'
import ErrorPage from '../ErrorPage'

const env = import.meta.env.VITE_ENVIRONMENT || 'local'

export default function PhotoProductsFallbackUi({
  error,
  resetError,
}: {
  error: Error | null
  resetError: () => void
}) {
  const navigate = useNavigate()

  const onClick = () => {
    resetError()
    navigate('/photo-products/categories')
  }

  if (error?.message === 'ERROR_CAUSED_BY_REFRESH' && env === 'local') {
    return (
      <ErrorPage
        error="Error caused by refresh"
        message="This error was caused by refreshing the page. This should not happen within a WebView."
        buttonText="Start over"
        onButtonClick={onClick}
      />
    )
  }

  return (
    <ErrorPage
      error="Something went wrong"
      message="We are sorry for the inconvenience. Please try again later or click below to start over."
      buttonText="Start over"
      onButtonClick={onClick}
    />
  )
}
