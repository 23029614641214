import {
  VariantShapeOption,
  VariantSizeOption,
} from '../../../clients/fagl-server/types/photoProducts'

export default function VariantGenericOptionSelector({
  updateVariantId,
  selectedVariantId,
  options,
  label,
}: {
  updateVariantId: (variantId: number) => void
  selectedVariantId: number
  options: {
    variantId: number
    option: VariantSizeOption | VariantShapeOption
  }[]
  label: string
}) {
  return (
    <div className="flex flex-row items-center gap-6">
      <span>{label}</span>
      <select
        className="border border-solid p-1 transition-all border-fa-border"
        value={selectedVariantId || options[0].variantId}
        onChange={(e) => updateVariantId(Number(e.target.value))}
      >
        {options.map(({ variantId, option: { value } }) => (
          <option key={variantId} value={variantId}>
            {value}
          </option>
        ))}
      </select>
    </div>
  )
}
