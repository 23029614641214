import { useCallback } from 'react'
import { Carousel, CarouselItem } from '../../../shared/Carousel'

export default function ProductPageUniqueTemplates({
  carouselImages,
  onNextClick,
}: {
  carouselImages: string[]
  onNextClick: (clickedElement: string) => void
}) {
  const onCarouselItemClick = useCallback(
    (index: number) => () => {
      onNextClick(`carousel-item-${index}`)
    },
    [onNextClick]
  )

  const onSeeMoreClick = useCallback(() => {
    onNextClick('carousel-see-more')
  }, [onNextClick])

  return (
    <section className="bg-amber-2 p-4 space-y-4 flex flex-col">
      <h3 className="font-bold text-lg">Unique Templates</h3>
      <Carousel
        scrollContainerClassName="space-x-4"
        items={carouselImages.map((src, index) => ({ src, id: index }))}
        renderItem={({ item, isSnapPoint, index }) => (
          <CarouselItem key={item.id} isSnapPoint={isSnapPoint} className="bg-white rounded-lg">
            <button onClick={onCarouselItemClick(index)}>
              <img src={item.src} className="rounded-lg" width="160" height="160" alt="" />
            </button>
          </CarouselItem>
        )}
      />
      <button
        onClick={onSeeMoreClick}
        className="font-bold self-end text-mi-primary block text-right"
      >
        See more design options
      </button>
    </section>
  )
}
