import { flatMap } from 'lodash'
import { PhotoProductCategory } from '../../clients/fagl-server/types'

const SOCKS_ORDERED_TEMPLATE_IDS = {
  male: [
    94, 95, 96, 93, 89, 90, 66, 92, 97, 98, 91, 70, 63, 64, 62, 61, 67, 68, 69, 65, 71, 72, 73, 74,
  ],
  female: [
    104, 105, 106, 103, 99, 100, 80, 102, 107, 108, 101, 84, 73, 74, 72, 71, 77, 78, 76, 75, 81, 82,
    83, 79, 85, 86, 87, 88,
  ],
}

const MUGS_ORDERED_TEMPLATE_IDS = {
  colorful: [
    215, 120, 5, 125, 2, 8, 110, 130, 145, 115, 29, 155, 160, 150, 140, 32, 17, 14, 165, 20, 26, 35,
    11, 23,
  ],
  _11oz: [
    217, 122, 39, 127, 37, 41, 112, 132, 147, 117, 55, 157, 162, 152, 142, 57, 47, 45, 167, 49, 53,
    59, 43, 51,
  ],
  _15oz: [
    218, 123, 40, 128, 38, 42, 113, 133, 148, 118, 56, 158, 163, 153, 143, 58, 48, 46, 168, 50, 54,
    60, 44, 52,
  ],
  enamel: [
    214, 119, 4, 124, 1, 7, 109, 129, 144, 114, 28, 154, 159, 149, 139, 31, 16, 13, 164, 19, 25, 34,
    10, 22,
  ],
  jumbo: [
    216, 121, 6, 126, 3, 9, 111, 131, 146, 116, 30, 156, 161, 151, 141, 33, 18, 15, 166, 21, 27, 36,
    12, 24,
  ],
}

const ORNAMENTS_ORDERED_TEMPLATE_IDS = {
  tree: [
    '72963-14',
    '72963-7',
    '72963-5',
    '72963-8',
    '72963-1',
    '72963-10',
    '72963-9',
    '72963-4',
    '72963-6',
    '72963-3',
    '72963-2',
    '72963-11',
    '72963-13',
    '72963-12',
  ],
  stocking: [
    '72960-14',
    '72960-7',
    '72960-5',
    '72960-8',
    '72960-1',
    '72960-10',
    '72960-9',
    '72960-4',
    '72960-6',
    '72960-3',
    '72960-2',
    '72960-11',
    '72960-13',
    '72960-12',
  ],
  star: [
    '72965-14',
    '72965-7',
    '72965-5',
    '72965-8',
    '72965-1',
    '72965-10',
    '72965-9',
    '72965-4',
    '72965-6',
    '72965-3',
    '72965-2',
    '72965-11',
    '72965-13',
    '72965-12',
  ],
}

const PUZZLES_ORDERED_TEMPLATE_IDS = {
  _120pcs: [208, 169, 172, 175, 193, 211],
  _252pcs: [209, 170, 173, 176, 194, 212],
  _500pcs: [210, 171, 174, 177, 195, 213],
}

export const ORDERED_TEMPLATE_IDS: Record<Exclude<PhotoProductCategory, 'ORNAMENTS'>, number[]> = {
  SOCKS: flatMap(SOCKS_ORDERED_TEMPLATE_IDS),
  MUGS: flatMap(MUGS_ORDERED_TEMPLATE_IDS),
  WRAPPING_PAPER: [],
  PUZZLES: flatMap(PUZZLES_ORDERED_TEMPLATE_IDS),
}

export const ORDERED_PSEUDO_TEMPLATE_IDS: Record<'ORNAMENTS', string[]> = {
  ORNAMENTS: flatMap(ORNAMENTS_ORDERED_TEMPLATE_IDS),
}
